import { Transaction } from '../../../api';

export function isToggledOffTx(toggledTransactions: { [txId: string]: boolean }, transaction: Transaction) {
  return (
    (toggledTransactions[transaction.id] !== undefined && toggledTransactions[transaction.id] === false) ||
    (toggledTransactions[transaction.id] !== true && transaction.ignored)
  );
}

export function isExcludedTx(toggledTransactions: { [txId: string]: boolean }, transaction: Transaction) {
  return isToggledOffTx(toggledTransactions, transaction) || !transaction.approved;
}
