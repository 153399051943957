import { Role } from './session';

export interface User {
  id?: string;
  firstName: string | null;
  lastName: string | null;
  legalName: string | null;
  email: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  province: string | null;
  country: string | null;
  postalCode: string | null;
  phoneNumber: string | null;
  organizationId: string | null;
  isRegistered: boolean;
  isVerified: boolean;
  isDisabled: boolean;
  role: Role;
  timeZone: string | null;
  emailIdentifier: string | null;
  dateOfBirth: string | null;
  personalEmail: string | null;
}

export interface UserPrivateDetails {
  sin: string;
}

export interface UserBankingDetails {
  bankingTransitNumber: string;
  bankingInstitutionNumber: string;
  bankingAccountNumber: string;
}

export interface Journal {
  id: string;
  organizationId: string;
  currency: string;
  fy: string;
  fyStart: Date;
  fyEnd: Date;
  archived: Date | null;
}

// Should be kept in sync with backend
export enum StandardAccounts {
  // Expenses
  ADVERTISING_AND_PROMOTION = 'Advertising & promotion',
  AUTOMOBILE = 'Automobile',
  BAD_DEBTS = 'Bad debts',
  BANK_CHARGES = 'Bank charges',
  BUSINESS_TAXES = 'Business taxes',
  INTEREST_EXPENSES = 'Interest expenses',
  EMPLOYEE_BENEFITS = 'Employee benefits',
  EQUIPMENT_RENTAL = 'Equipment rental',
  GENERAL_AND_ADMINISTRATIVE_EXPENSES = 'General & administrative expenses',
  CLIENT_GIFT = 'Client gifts',
  DUES_AND_SUBSCRIPTIONS = 'Dues & subscriptions',
  INSURANCE = 'Insurance',
  MANAGEMENT_AND_ADMINISTRATION_FEES = 'Management & administration fees',
  DONATIONS = 'Donations',
  MEALS_AND_ENTERTAINMENT = 'Meals & entertainment',
  OFFICE_EXPENSES = 'Office expenses',
  OFFICE_RENT = 'Office rent',
  PAYROLL_SALARY_AND_WAGES = 'Payroll - salary & wages',
  PROFESSIONAL_FEES = 'Professional fees',
  LEGAL_FEES = 'Legal fees',
  ACCOUNTING_FEES = 'Accounting fees',
  CONSULTING_FEES = 'Consulting fees',
  REPAIR_AND_MAINTENANCE = 'Repair & maintenance',
  SUPPLIES = 'Supplies',
  TELEPHONE_EXPENSES = 'Telephone expenses',
  TRAVEL = 'Travel',
  UNCATEGORIZED_EXPENSES = 'Uncategorized expenses',
  TRAINING = 'Training',
  PARKING = 'Parking',
  UTILITIES = 'Utilities',
  OTHER_DIRECT_COST = 'Other direct cost',
  LOSS_ON_FOREIGN_EXCHANGE = 'Loss on foreign exchange',
  EXPENSE_REIMBURSEMENT = 'Expense reimbursement',
  AMORTIZATION_OF_INTANGIBLE_ASSETS = 'Amortization of intangible assets',
  AMORTIZATION_OF_COMPUTER_EQUIPMENT_SOFTWARE = 'Amortization of computer equipment/software',
  AMORTIZATION_OF_FURNITURE_AND_FIXTURES = 'Amortization of furniture and fixtures',
  AMORTIZATION_OF_MOTOR_VEHICLES = 'Amortization of motor vehicles',
  AMORTIZATION_OF_SMALL_TOOLS = 'Amortization of small tools',
  AMORTIZATION_OF_INCORPORATION_COSTS = `Amortization of incorporation costs`,

  // Income
  GAIN_ON_FOREIGN_EXCHANGE = 'Gain on foreign exchange',
  REALIZED_GAINS_LOSSES_ON_DISPOSAL_OF_ASSETS = 'Realized gains/losses on disposal of assets',
  DIVIDEND_INCOME = 'Dividend income',
  RENTAL_INCOME = 'Rental income',
  INTEREST_RECEIVED = 'Interest received',
  REWARDS_AND_REFUNDS = 'Rewards & refunds',
  OTHER_INCOME = 'Other income',
  SALES = 'Sales',
  INVESTMENTS = 'Investments',
  UNCATEGORIZED_INCOME = 'Uncategorized income',

  // Cost of sales
  SUPPLIES_AND_MATERIALS = 'Supplies & materials',
  SUBCONTRACTS = 'Subcontracts',
  DIRECT_WAGES = 'Direct wages',

  // Current assets
  CASH = 'Cash (Bank)',
  UNDEPOSITED_FUNDS = 'Undeposited funds',
  SHORT_TERM_INVESTMENTS = 'Investments (short-term)',
  GICS_TERM_DEPOSITS = 'GICs/term deposits',
  LONG_TERM_INVESTMENTS = 'Investments (long-term)',
  GST_HST_RECEIVABLE = 'GST/HST receivable',
  ACCOUNTS_RECEIVABLE = 'Accounts receivable (A/R)',
  UNCATEGORIZED_ASSETS = 'Uncategorized assets',
  PREPAID_EXPENSES = 'Prepaid expenses',
  SMALL_TOOLS = 'Small tools',
  DUE_FROM_SHAREHOLDER = 'Due from shareholder',
  DUE_FROM_RELATED_PARTIES = 'Due from related parties',
  LINE_OF_CREDIT = 'Line of credit',

  // Property, plant, and equipment
  ACCUMULATED_DEPRECIATION_COMPUTER_EQUIPMENT_SOFTWARE = 'Accumulated depreciation - computer equipment/software',
  COMPUTER_EQUIPMENT_SOFTWARE = 'Computer equipment/software',
  ACCUMULATED_DEPRECIATION_FURNITURE_AND_FIXTURES = 'Accumulated depreciation - furniture & fixtures',
  FURNITURE_AND_FIXTURES = 'Furniture & fixtures',
  ACCUMULATED_DEPRECIATION_MOTOR_VEHICLES = 'Accumulated depreciation - motor vehicles',
  MOTOR_VEHICLES = 'Motor vehicles',
  INTANGIBLE_ASSETS = 'Intangible assets',
  ACCUMULATED_DEPRECIATION_INTANGIBLE_ASSETS = 'Accumulated depreciation - intangible assets',
  ACCUMULATED_DEPRECIATION_SMALL_TOOLS = 'Accumulated depreciation - small tools',
  INCORPORATION_COSTS = 'Incorporation costs',
  ACCUMULATED_DEPRECIATION_INCORPORATION_COSTS = 'Accumulated depreciation - incorporation costs',

  // Long-term assets
  PRIVATE_INVESTMENTS = 'Private investments',

  // Current liabilities
  PERSONAL_AND_CREDIT_CARD_LOANS = 'Personal & credit card loans',
  ACCOUNTS_PAYABLE = 'Accounts payable (A/P)',
  BONUSES_PAYABLE = 'Bonuses payable',
  TRADE_PAYABLES = 'Trade payables',
  DUE_TO_SHAREHOLDER = 'Due to shareholder',
  DUE_TO_RELATED_PARTIES = 'Due to related parties',
  GST_HST_PAYABLE = 'GST/HST payable',
  GST_HST_SUSPENSE = 'GST/HST suspense',
  PAYROLL_WH_PAYABLE = 'Payroll w/h payable',
  PST_PAYABLE = 'PST payable',
  PST_SUSPENSE = 'PST suspense',
  INCOME_TAXES_PAYABLE = 'Income taxes payable',
  DIVIDENDS_PAYABLE = 'Dividends payable',
  DEFERRED_REVENUE = 'Deferred revenue',

  // Equity
  RETAINED_EARNINGS_DEFICIT = 'Retained earnings/deficit',
  COMMON_SHARES = 'Common shares',
  DIVIDENDS_DECLARED = 'Dividends declared',
  CASH_DIVIDENDS = 'Cash dividends',
  SHARE_CAPITAL = 'Share capital',
}

export enum LogicalType {
  GENERAL_ACCOUNT = 'GENERAL_ACCOUNT',
}

export enum SyncType {
  STATEMENT_UPLOAD = 'STATEMENT_UPLOAD',
  AUTOMATIC = 'AUTOMATIC',
}

export enum InstitutionId {
  BMO = 'ins_41',
  RBC = 'ins_39',
  CIBC = 'ins_37',
  AMEX = 'ins_100533',

  VAULT = 'VAULT',
  LOOP = 'LOOP',
  FLOAT = 'FLOAT',
}

export enum ExpectedStatementMimeType {
  APPLICATION_PDF = 'application/pdf',
  TEXT_CSV = 'text/csv',
}

export enum TopLevelAccountType {
  ASSETS = 'ASSETS',
  LIABILITIES = 'LIABILITIES',
  EQUITY = 'EQUITY',
  REVENUE = 'REVENUE',
  EXPENSES = 'EXPENSES',
}

export enum AccountType {
  EXPENSES = 'Expenses',
  INCOME = 'Income',
  COST_OF_GOODS_SOLD = 'Cost of Goods Sold',
  CURRENT_ASSETS = 'Current Assets (Assets)',
  LONG_TERM_ASSETS = 'Long-term Assets (Assets)',
  PROPERTY_PLANT_EQUIPMENT = 'Property, plant, and equipment (Assets)',
  CURRENT_LIABILITIES = 'Current Liabilities (Liabilities)',
  LONG_TERM_LIABILITIES = 'Long-term Liabilities (Liabilities)',
  EQUITY = 'Equity',
  CAPITAL_ASSETS = 'Capital Assets',
}

export enum Province {
  ALBERTA = 'ALBERTA',
  BRITISH_COLUMBIA = 'BRITISH_COLUMBIA',
  MANITOBA = 'MANITOBA',
  NEW_BRUNSWICK = 'NEW_BRUNSWICK',
  NEWFOUNDLAND_AND_LABRADOR = 'NEWFOUNDLAND_AND_LABRADOR',
  NORTHWEST_TERRITORIES = 'NORTHWEST_TERRITORIES',
  NOVA_SCOTIA = 'NOVA_SCOTIA',
  NUNAVUT = 'NUNAVUT',
  ONTARIO = 'ONTARIO',
  PRINCE_EDWARD_ISLAND = 'PRINCE_EDWARD_ISLAND',
  QUEBEC = 'QUEBEC',
  SASKATCHEWAN = 'SASKATCHEWAN',
  YUKON = 'YUKON',
}

export enum Country {
  CANADA = 'CANADA',
}

export function getDepth(pathObj: { parentId: string; path: string }) {
  if (!pathObj.parentId) {
    return 0;
  }

  let depth = 1;
  for (let i = 0; i < pathObj.path.length; i++) {
    if (pathObj.path.charAt(i) === '/') {
      depth++;
    }
  }
  return depth;
}

export interface Account {
  id: string;
  parentId?: string | null;
  path: string;
  name: string;
  standardAccount: StandardAccounts;
  gifiType: string;
  externalId: string | null;
  externalMaskedAccountId: string | null;
  externalBalance: string | null;
  externalType: string | null;
  convertedExternalBalance: string | null;
  externalCurrency: string | null;
  consolidatedBalance: string;
  standaloneBalance: string;
  logicalType: LogicalType | null;
  description: string;
  syncType: SyncType | null;
  statementAvailabilityDayOfMonth: number | null;
  statementType: string | null;
  topLevelType: TopLevelAccountType;
  type: AccountType;
  institutionId: InstitutionId | null;
}

export enum DocumentType {
  INVOICE = 'invoice',
  RECEIPT = 'receipt',
}

export enum OtterProcessingStatus {
  NOT_STARTED = 'NOT_STARTED',
  PARSED = 'PARSED',
  REVIEWED = 'REVIEWED',
  COMPLETE = 'COMPLETE',
}

export enum PaymentPeriod {
  MONTHLY = 'MONTHLY',
}

export const deserializeDocument = (d: SerializedDocument) => {
  const date = d.dateTime ? new Date(d.dateTime) : null;
  const dueDate = d.dueDate ? new Date(d.dueDate) : null;
  const paymentDate = d.paymentDateTime ? new Date(d.paymentDateTime) : null;
  const prepaymentStartDate = d.prepaymentStartDate ? new Date(d.prepaymentStartDate) : null;

  const notes = (d.notes || []).map(deserializeDocumentNote);

  const deserialized: Document = {
    ...d,
    date,
    dueDate,
    paymentDate,
    prepaymentStartDate,
    notes,
    created: new Date(d.created),
    updated: new Date(d.updated),
  };

  return deserialized;
};

export type SerializedDocument = Omit<
  Document,
  'date' | 'dateAlt' | 'paymentDate' | 'prepaymentStartDate' | 'created' | 'updated' | 'notes' | 'dueDate'
> & {
  dateTime: string;
  dueDate: string | null;
  paymentDateTime: string;
  prepaymentStartDate: string;
  notes: SerializedDocumentNote[];
  created: string;
  updated: string;
};

export enum DocumentUnmatchedReason {
  PERSONAL_EXPENSE = 'PERSONAL_EXPENSE',
  PAID_FOR_PERSONALLY = 'PAID_FOR_PERSONALLY',
  PAYMENT_ACCOUNT_NUMBER = 'PAYMENT_ACCOUNT_NUMBER',
  UNKNOWN = 'UNKNOWN',
}

export enum PaidForPersonallyHandling {
  REIMBURSEMENT = 'REIMBURSEMENT',
  DUE_TO_SHAREHOLDER = 'DUE_TO_SHAREHOLDER',
}

export function documentTotal(document: Document) {
  return parseFloat(document.afterTax || '0') - parseFloat(document.discount || '0');
}

export interface Document {
  id: string;
  date: Date | null;
  dueDate: Date | null;
  merchantName: string | null;
  merchantNameAlt: string | null;
  client: string | null;
  beforeTax: string | null;
  beforeTaxAlt: string | null;
  afterTax: string | null;
  afterTaxAlt: string | null;
  GST: string | null;
  GSTAlt: string | null;
  HST: string | null;
  HSTAlt: string | null;
  PST: string | null;
  PSTAlt: string | null;
  alreadyPaid: boolean;
  paymentCardType: string | null;
  paymentAccountNumber: string | null;
  paymentDate: Date | null;
  currency: string;
  currencyAlt: string;
  signedUrl: string;
  thumbnailSignedUrl: string | null;
  fileName: string;
  fileType: string;
  type: DocumentType;
  otterProcessingStatus: OtterProcessingStatus;
  valuesOverridden: boolean;
  ignored: boolean;
  isIncome: boolean;
  assignedCategory: string | null;
  prepayment: boolean;
  prepaymentStartDate: Date | null;
  prepaymentAmount: string | null;
  prepaymentPeriod: PaymentPeriod | null;
  prepaymentNumberOfPayments: number | null;
  description: string;
  ignoreMatchRequirement: boolean;
  notes: DocumentNote[];
  transactionMatchHint: string | null;
  issues: {
    unmatched?: boolean;
    uncategorized?: boolean;
  };
  reimbursement: boolean;
  reimburseToUserId: string | null;
  reimbursementApproved: boolean;
  dueToShareholder: boolean;
  checkInStatus: CheckInStatus | null;
  checkInDescription: string | null;
  checkInUnmatchedReason: DocumentUnmatchedReason | null;
  checkInPaymentAccountSpecified: string | null;
  checkInPaidForPersonallyHandling: string | null;
  checkInReimburseToUserId: string | null;
  discount: string | null;
  created: Date;
  updated: Date;
}

export interface DocumentNote {
  id: string;
  documentId: string;
  content: string;
  source: string;
  created: Date;
}

export type SerializedDocumentNote = Omit<DocumentNote, 'created'> & { created: string };

export const deserializeDocumentNote = (note: SerializedDocumentNote) => {
  return {
    ...note,
    created: new Date(note.created),
  };
};

export type SerializedTx = Omit<Transaction, 'date' | 'postedDate'> & { date: string; postedDate: string };

export enum CheckInStatus {
  SCHEDULED = 'SCHEDULED',
  SAVED_FOR_LATER = 'SAVED_FOR_LATER',
  CLARIFIED = 'CLARIFIED',
  SIGNED_OFF = 'SIGNED_OFF',
  CONFLICT = 'CONFLICT',
}

export enum SpecialStatus {
  PART_OF_TRANSFER = 'PART_OF_TRANSFER',
  REFUNDED = 'REFUNDED',
}

export interface Transaction {
  id: string;
  organizationId: string;
  accountId: string;
  amount: string;
  postedDate: Date;
  date: Date;
  isoCurrencyCode: string;
  merchantName: string | null;
  name: string;
  pending: boolean;
  suggestedCategory: string | null;
  assignedCategory: string | null;
  categorizationConfidence: number | null;
  specialStatus: SpecialStatus | null;
  ignored: boolean;
  approved: boolean;
  autoApproved: boolean;
  ignoreMatchRequirement: boolean;
  overrideDuplicateDetection: boolean;
  issues: {
    unmatched?: boolean;
    uncategorized?: boolean;
  };
  checkInStatus: CheckInStatus | null;
  checkInDescription: string | null;
  checkInDocuments: string[] | null;
  checkInNoDocuments: boolean | null;
  checkInAwaitingDocumentEmail: boolean | null;
}

export const deserializeTransaction = (tx: SerializedTx) => {
  return {
    ...tx,
    date: new Date(tx.date),
    postedDate: new Date(tx.postedDate),
  };
};
