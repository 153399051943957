import { SimpleTable } from '../simple-table';
import { IWidgetProps } from './IWidgetProps';

export interface TableWidgetProps extends IWidgetProps {
  columns?: Array<{
    header: string;
    align?: 'left' | 'center' | 'right';
  }>;
  rows?: string[][];
}

export function TableWidget({ columns, rows }: TableWidgetProps) {
  return (
    <SimpleTable>
      <thead>
        <tr>
          {columns!.map((c) => (
            <th key={c.header} align={c.align || 'left'}>
              {c.header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {!rows?.length && (
          <tr>
            <td colSpan={2} align='center'>
              No Reimbursements
            </td>
          </tr>
        )}
        {rows!.map((r, i) => (
          <tr key={i}>
            {r.map((c, ci) => (
              <td key={ci} align={columns![ci].align || 'left'}>
                {c}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </SimpleTable>
  );
}
