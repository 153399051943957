import { CircularProgress, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Account, Journal } from '../../../api';
import { AdminJournalSelect, AdminOrganizationSelect, PageBody, PageContainer, PageHeader, useSelectedOrganization } from '../../../components';
import { MED_VERTICAL_SPACING, SMALL_VERTICAL_SPACING } from '../../../theme';
import { ReconciliationContext, useReconciliationData } from './reconciliation-context';

export function AdminReconciliationPage({ ...props }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { accountId } = useParams();

  const { selectedOrganization: selectedOrg, setSelectedOrganization: setSelectedOrg, previousSelectedOrganization } = useSelectedOrganization(null);
  const [selectedJournal, setSelectedJournal] = useState<Journal | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);

  const reconciliationData = useReconciliationData(selectedOrg, selectedJournal, selectedAccount);
  const { organizations, journals, accounts, account } = reconciliationData;

  useEffect(() => {
    setSelectedAccount((current) => {
      if (current?.id === accountId) {
        return current;
      }

      return accounts?.find((a) => a.id === accountId) || null;
    });
  }, [accountId, accounts]);

  if (!organizations) {
    return (
      <PageContainer {...props}>
        <PageHeader title='Admin - Reconciliation' />
        <PageBody gutter='thin' style={{ alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </PageBody>
      </PageContainer>
    );
  }

  return (
    <PageContainer {...props}>
      {!account && <PageHeader title='Admin - Reconciliation' />}
      <PageBody gutter='thin' style={{ paddingTop: account ? theme.spacing(MED_VERTICAL_SPACING) : undefined }}>
        <Stack height='100%' spacing={SMALL_VERTICAL_SPACING}>
          <Stack direction='row' paddingTop={theme.spacing(2)}>
            <AdminOrganizationSelect
              organizations={organizations}
              onOrganizationChange={(org) => {
                setSelectedOrg(org);
                if (selectedOrg?.id !== previousSelectedOrganization?.id) {
                  setSelectedJournal(null);
                  setSelectedAccount(null);
                  navigate('/admin/reconciliation');
                }
              }}
            />

            <AdminJournalSelect
              journals={journals || []}
              organization={selectedOrg}
              selectedJournal={selectedJournal}
              onJournalChange={(j) => {
                setSelectedJournal(j);
                setSelectedAccount(null);
                navigate('/admin/reconciliation');
              }}
            />
          </Stack>

          <ReconciliationContext.Provider value={reconciliationData}>
            <Outlet />
          </ReconciliationContext.Provider>
        </Stack>
      </PageBody>
    </PageContainer>
  );
}
