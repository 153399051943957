import { JournalEntry } from '../api';

export function entryIsCurrent(e: JournalEntry) {
  return (
    !e.tags.length ||
    e.tags.find(
      (t) =>
        t !== 'reversal' &&
        t !== 'reversed' &&
        t !== 'opening-balance-reversal' &&
        !t.startsWith('reversal::') &&
        !t.startsWith('document_reversal::') &&
        !t.startsWith('transaction::') &&
        !t.startsWith('document::') &&
        !t.startsWith('old-opening-balances::')
    )
  );
}
