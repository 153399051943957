import { alpha, Box, CircularProgress, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { SearchZoomIn, SearchZoomOut, Warning2 } from 'iconsax-react';
import { CSSProperties, ImgHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import { Document, useDocument } from '../api';
import { SMALL_HORIZONTAL_SPACING, SMALL_VERTICAL_SPACING } from '../theme';

export interface DocumentImgProps extends Omit<ImgHTMLAttributes<HTMLImageElement>, 'src' | 'width' | 'height' | 'style'> {
  containerWidth?: string | number | undefined;
  containerHeight?: string | number | undefined;
  containerBackground?: string | undefined;
  imgWidth?: string | number | undefined;
  imgHeight?: string | number | undefined;
  imgFillContainer?: boolean;
  document: Document;
  thumbnail?: boolean;
  containerStyle?: CSSProperties;
  imgStyle?: CSSProperties;
}

export function DocumentImg({
  containerBackground,
  containerWidth,
  containerHeight,
  containerStyle,
  document,
  thumbnail,
  imgWidth,
  imgHeight,
  imgFillContainer,
  imgStyle,
  ...props
}: DocumentImgProps) {
  const theme = useTheme();
  const [src, setSrc] = useState(thumbnail ? document.signedUrl : document.thumbnailSignedUrl || '/pdf-file-icon.svg');

  const { refreshSignedUrls } = useDocument();

  const attemptedErrorHandling = useRef(false);
  const onError = useCallback(async () => {
    if (attemptedErrorHandling.current) {
      return;
    }

    const { signedUrl, thumbnailSignedUrl } = await refreshSignedUrls(document.id);

    attemptedErrorHandling.current = true;

    setSrc(thumbnail ? thumbnailSignedUrl || '/pdf-file-icon.svg' : signedUrl);
  }, [refreshSignedUrls, document, thumbnail]);

  let iWidth, iHeight;
  if (src === '/pdf-file-icon.svg') {
    iWidth = 64;
    iHeight = 64;
  } else if (imgFillContainer) {
    iWidth = '100%';
    iHeight = '100%';
  }

  const img = (
    <img
      src={src || undefined}
      alt={document.merchantName || 'Unknown Document'}
      width={iWidth}
      height={iHeight}
      {...props}
      onError={onError}
      style={{
        objectFit: imgFillContainer && src !== '/pdf-file-icon.svg' ? 'cover' : undefined,
        ...imgStyle,
      }}
    />
  );

  if (containerBackground || containerHeight || containerWidth) {
    return (
      <Box
        style={{
          width: containerWidth,
          height: containerHeight,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: `1px solid ${theme.palette.border.main}`,
          borderRadius: theme.roundedCorners(5),
          background: containerBackground,
          overflow: 'hidden',
          ...containerStyle,
        }}
      >
        {img}
      </Box>
    );
  } else {
    return img;
  }
}

export interface ViewerControlsProps {
  onZoomIn: () => void;
  onZoomOut: () => void;
  style?: CSSProperties;
}

export function ViewerControls({ onZoomIn, onZoomOut, style }: ViewerControlsProps) {
  const theme = useTheme();

  return (
    <Stack direction='row' spacing={SMALL_HORIZONTAL_SPACING} style={style}>
      <Tooltip title='Zoom in'>
        <span>
          <IconButton onClick={onZoomIn} style={{ background: alpha('#000', 0.4) }}>
            <SearchZoomIn size='1rem' color={theme.palette.text.primary} />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip title='Zoom out'>
        <span>
          <IconButton onClick={onZoomOut} style={{ background: alpha('#000', 0.4) }}>
            <SearchZoomOut size='1rem' color={theme.palette.text.primary} />
          </IconButton>
        </span>
      </Tooltip>
    </Stack>
  );
}

export interface DocumentViewerProps {
  imgWidth?: string | number | undefined;
  imgHeight?: string | number | undefined;
  document: Document;
  style?: CSSProperties;
}

export function DocumentViewer({ document, imgWidth, imgHeight, ...props }: DocumentViewerProps) {
  const theme = useTheme();
  const [src, setSrc] = useState<string | null>(null);
  const [error, setError] = useState(false);
  const [zoom, setZoom] = useState<'zoomIn' | 'zoomOut'>('zoomOut');

  const { refreshSignedUrls } = useDocument();

  const attemptedValidation = useRef(false);
  useEffect(() => {
    const validate = async () => {
      const response = await fetch(document.signedUrl);

      if (response.status === 400) {
        const { signedUrl } = await refreshSignedUrls(document.id);

        setSrc(signedUrl);
      } else if (response.status >= 400) {
        setError(true);
      } else {
        setSrc(document.signedUrl);
      }
    };

    if (attemptedValidation.current) {
      return;
    }

    validate().catch((e) => {
      throw e;
    });
  }, [refreshSignedUrls, document]);

  if (src && document.fileType.startsWith('image/')) {
    return (
      <Box flex={1} minWidth={0} minHeight={0} position='relative' {...props}>
        <ViewerControls
          onZoomIn={() => setZoom('zoomIn')}
          onZoomOut={() => setZoom('zoomOut')}
          style={{
            position: 'absolute',
            top: theme.spacing(SMALL_VERTICAL_SPACING),
            left: '50%',
            transform: `translateX(-50%)`,
          }}
        />
        <Stack width='100%' height='100%' alignItems='center' overflow='auto' {...props}>
          <img
            src={src}
            style={{
              width: zoom === 'zoomOut' ? '100%' : undefined,
              height: zoom === 'zoomOut' ? '100%' : undefined,
              objectFit: zoom === 'zoomOut' ? 'contain' : 'none',
              border: 'none',
            }}
          />
        </Stack>
      </Box>
    );
  } else if (src) {
    return (
      <Box flex={1} minWidth={0} minHeight={0} position='relative' {...props}>
        <iframe src={src} {...props} style={{ border: 'none', height: '100%', width: '100%', ...props.style }}></iframe>
      </Box>
    );
  } else if (error) {
    return (
      <Stack justifyContent='center' alignItems='center' {...props}>
        <Warning2 color={theme.palette.error.main} />
        <Typography color='error'>An error occurred</Typography>
      </Stack>
    );
  } else {
    return (
      <Stack justifyContent='center' alignItems='center' {...props}>
        <CircularProgress />
      </Stack>
    );
  }
}
