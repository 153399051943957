import { IWidgetProps } from './IWidgetProps.tsx';
import { ButtonWidget } from './button-widget.tsx';
import { DocumentClarificationWidget } from './check-in/document-clarification-widget.tsx';
import { EmailDocumentReminderWidget } from './check-in/email-document-reminder-widget.tsx';
import { ScheduleCheckInWidget } from './check-in/schedule-check-in-widget.tsx';
import { TransactionClarificationWidget } from './check-in/transaction-clarification-widget.tsx';
import { ContinueButtonWidget } from './continue-button-widget.tsx';
import { DocumentEmailAddressWidget } from './document-email-address-widget.tsx';
import { IncomeDescriptionWidget } from './income-description-widget.tsx';
import { InsightPanelWidget } from './insight-panel-widget.tsx';
import { LinkWidget } from './link-widget.tsx';
import { OnboardingSplashWidget } from './onboarding-splash-widget.tsx';
import { BankAccountSummaryWidget } from './plaid-account-summary-widget.tsx';
import { PlaidCreateConnectionWidget } from './plaid-create-connection-widget.tsx';
import { ReimbursementApprovalWidget } from './reimbursement-approval/reimbursement-approval-widget.tsx';
import { StatementUploadListWidget } from './statement-upload-list-widget.tsx';
import { TableWidget } from './table-widget.tsx';
import { UploadReceiptsWidget } from './upload-receipts.tsx';

export interface fetchWidgetProps<T extends IWidgetProps> {
  widgetType: string;
  props: T;
}

export const fetchWidget = <T extends IWidgetProps>({ widgetType, props }: fetchWidgetProps<T>) => {
  switch (widgetType) {
    case 'INSIGHT_PANEL_WIDGET':
      return <InsightPanelWidget {...props} />;
    case 'WidgetLink':
      return <LinkWidget {...props} />;
    case 'PLAID_LINK_WIDGET':
    case 'PLAID_CREATE_CONNECTION_WIDGET':
      return <PlaidCreateConnectionWidget {...props} />;
    case 'IncomeDescriptionWidget':
      return <IncomeDescriptionWidget />;
    case 'UPLOAD_RECEIPTS_WIDGET':
      return <UploadReceiptsWidget {...props} />;
    case 'ContinueButtonWidget':
      return <ContinueButtonWidget {...props} />;
    case 'BANK_ACCOUNT_SUMMARY_WIDGET':
      return <BankAccountSummaryWidget {...props} />;
    case 'BUTTON_WIDGET':
      return <ButtonWidget {...props} />;
    case 'ONBOARDING_SPLASH_WIDGET':
      return <OnboardingSplashWidget {...props} />;
    case 'STATEMENT_UPLOAD_LIST_WIDGET':
      return <StatementUploadListWidget {...props} />;
    case 'SCHEDULE_CHECK_IN_WIDGET':
      return <ScheduleCheckInWidget {...props} />;
    case 'TRANSACTION_CLARIFICATION_WIDGET':
      return <TransactionClarificationWidget {...props} />;
    case 'DOCUMENT_CLARIFICATION_WIDGET':
      return <DocumentClarificationWidget {...props} />;
    case 'EMAIL_DOCUMENT_REMINDER_WIDGET':
      return <EmailDocumentReminderWidget {...props} />;
    case 'REIMBURSEMENT_APPROVAL_WIDGET':
      return <ReimbursementApprovalWidget {...props} />;
    case 'TABLE_WIDGET':
      return <TableWidget {...props} />;
    case 'DOCUMENT_EMAIL_ADDRESS_WIDGET':
      return <DocumentEmailAddressWidget {...props} />;
    default:
      return <div>WidgetNotFound</div>;
  }
};
