import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Button as MuiButton,
  Select,
  Stack,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { AddCircle, ArrowLeft2, CloseCircle, Edit2, Eye, MoreCircle, TickCircle } from 'iconsax-react';
import { HTMLAttributes, useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  Account,
  Checkpoint,
  CreateJournalEntryArgs,
  JournalEntry,
  JournalEntryLineType,
  StandardAccounts,
  TopLevelAccountType,
  Transaction,
} from '../../../api';
import { Button, ConfirmDialog, ThreeColumn } from '../../../components';
import { SimpleTable } from '../../../components/simple-table';
import { SMALL_HORIZONTAL_SPACING, SMALL_VERTICAL_SPACING } from '../../../theme';
import { entryIsCurrent } from '../../../utils/journal-entry-util';
import { JournalEntryDialog } from '../journal/journal-entry-dialog';
import { ImportTransactionsDialog } from '../transactions/import-transactions';
import { AccountCoverage } from './account-coverage';
import { ReconciliationContext } from './reconciliation-context';
import { isExcludedTx, isToggledOffTx } from './util';

function AccountSelect({ accounts, selected, onSelect }: { accounts: Account[]; selected: Account; onSelect: (account: Account) => void }) {
  const theme = useTheme();

  const onChangeAccount = (accountId: string) => {
    const account = accounts.find((a) => a.id === accountId);
    onSelect(account!);
  };

  return (
    <FormControl size='small'>
      <InputLabel id='account-select-label'>Account</InputLabel>
      <Select
        label='Account'
        labelId='account-select-label'
        autoWidth
        value={selected?.id || ''}
        onChange={(event) => onChangeAccount(event.target.value)}
        style={{
          minWidth: theme.spacing(36),
        }}
      >
        {accounts
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((o) => (
            <MenuItem key={o.id} value={o.id}>
              {o.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

function CheckpointSelect({
  checkpoints,
  selected,
  onSelect,
}: {
  checkpoints: Checkpoint[];
  selected: Checkpoint | null;
  onSelect: (account: Checkpoint | null) => void;
}) {
  const theme = useTheme();

  const onChangeCheckpoint = (checkpointId: string) => {
    if (checkpointId === '') {
      onSelect(null);
    } else {
      const checkpoint = checkpoints.find((a) => a.id === checkpointId);
      onSelect(checkpoint!);
    }
  };

  return (
    <FormControl size='small'>
      <InputLabel id='checkpoint-select-label'>Checkpoint</InputLabel>
      <Select
        label='Checkpoint'
        labelId='checkpoint-select-label'
        autoWidth
        value={selected?.id || ''}
        onChange={(event) => onChangeCheckpoint(event.target.value)}
        style={{
          minWidth: theme.spacing(24),
        }}
      >
        <MenuItem value={''}>None</MenuItem>
        {checkpoints
          .sort((a, b) => a.date.getTime() - b.date.getTime())
          .map((o) => (
            <MenuItem key={o.id} value={o.id}>
              <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
                <span>{format(o.date, 'MMM d')}</span>
                {o.reconciled ? (
                  <TickCircle size={16} variant='Bold' color={theme.palette.primary.main} />
                ) : (
                  <CloseCircle size={16} variant='Bold' color={theme.palette.error.main} />
                )}
              </Stack>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

interface MetricCardProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  primaryContent: React.ReactNode;
  secondaryContent?: React.ReactNode;
}

function MetricCard({ title, primaryContent, secondaryContent, ...props }: MetricCardProps) {
  const theme = useTheme();

  return (
    <Stack
      border={`1px solid ${theme.palette.border.main}`}
      borderRadius={theme.roundedCorners(5)}
      paddingX={theme.spacing(SMALL_HORIZONTAL_SPACING)}
      paddingY={theme.spacing(SMALL_VERTICAL_SPACING)}
      spacing={theme.spacing(SMALL_VERTICAL_SPACING)}
      {...props}
    >
      <Typography variant='h5'>{title}</Typography>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        {primaryContent}
        {secondaryContent}
      </Stack>
    </Stack>
  );
}

function TransactionsTable({ ...props }) {
  const theme = useTheme();
  const {
    account,
    accounts,
    organization,
    toggledTransactions,
    setTransactionToggle,
    checkpointTransactions,
    checkpointTransactionTotal,
    selectedTransactions,
    updateSelectedTransactions,
  } = useContext(ReconciliationContext);

  const [showImportDialog, setShowImportDialog] = useState(false);

  const accountAmountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: account?.externalCurrency || 'CAD' });

  const columns: GridColDef[] = [
    {
      display: 'flex',
      field: 'toggle',
      headerName: '',
      width: 32,
      align: 'center',
      renderCell: (params) => {
        const transaction = params.row as Transaction;
        const isToggledOff = isToggledOffTx(toggledTransactions, transaction);

        if (!transaction.approved) {
          return (
            <Tooltip title='View Unreviewed'>
              <span>
                <IconButton onClick={() => window.open('/admin/transactions?tab=UNREVIEWED', '_blank')}>
                  <Eye variant='Bold' size='1rem' color={theme.palette.primary.main} />
                </IconButton>
              </span>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={isToggledOff ? 'Unexclude' : 'Exclude'}>
              <Switch size='small' checked={!isToggledOff} onChange={(event) => setTransactionToggle(transaction.id, event.target.checked)} />
            </Tooltip>
          );
        }
      },
    },
    {
      display: 'flex',
      field: 'description',
      headerName: 'Transaction',
      flex: 1,
      sortComparator: (_v1, _v2, param1, param2) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        const a = param1.api.getRow(param1.id) as Transaction;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        const b = param2.api.getRow(param2.id) as Transaction;

        return (a.postedDate || a.date).getTime() - (b.postedDate || b.date).getTime();
      },
      renderCell: (params) => {
        const transaction = params.row as Transaction;
        const isExcluded = isExcludedTx(toggledTransactions, transaction);

        return (
          <Box>
            <Typography
              color={isExcluded ? theme.palette.text.disabled : undefined}
              style={{
                fontStyle: isExcluded ? 'italic' : undefined,
              }}
            >
              {transaction.name}
            </Typography>
            <Typography
              variant='small'
              color={isExcluded ? theme.palette.text.disabled : undefined}
              style={{
                fontStyle: isExcluded ? 'italic' : undefined,
              }}
            >
              {format(transaction.postedDate || transaction.date, 'MMM d')}
            </Typography>
          </Box>
        );
      },
    },
    {
      display: 'flex',
      field: 'amount',
      headerName: 'Amount',
      headerAlign: 'right',
      align: 'right',
      sortable: false,
      renderCell: (params) => {
        const transaction = params.row as Transaction;
        const txAmountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: transaction.isoCurrencyCode || 'CAD' });
        const isExcluded = isExcludedTx(toggledTransactions, transaction);

        return (
          <Typography
            color={isExcluded ? theme.palette.text.disabled : undefined}
            style={{
              fontStyle: isExcluded ? 'italic' : undefined,
            }}
          >
            {txAmountFormatter.format(-1 * parseFloat(transaction.amount))}
          </Typography>
        );
      },
    },
  ];

  let numExcluded = 0;
  let numIncluded = 0;
  for (const change of Object.values(toggledTransactions)) {
    if (change) {
      numIncluded++;
    } else {
      numExcluded++;
    }
  }

  let txText = `${(checkpointTransactions || []).length} items`;
  if (numExcluded && numIncluded) {
    txText += ` (${numIncluded} included, ${numExcluded} excluded)`;
  } else if (numExcluded) {
    txText += ` (${numExcluded} excluded)`;
  } else if (numIncluded) {
    txText += ` (${numIncluded} included)`;
  }

  return (
    <Stack spacing={SMALL_VERTICAL_SPACING} {...props}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='h4'>Transactions</Typography>
        {organization && accounts && (
          <>
            <IconButton size='small' onClick={() => setShowImportDialog(true)}>
              <AddCircle color={theme.palette.primary.main} size='1rem' />
            </IconButton>
            <ImportTransactionsDialog
              open={showImportDialog}
              onClose={() => setShowImportDialog(false)}
              organizationId={organization.id!}
              accounts={accounts}
              style={{
                flex: 1,
              }}
            />
          </>
        )}
      </Stack>

      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography>{txText}</Typography>

        <Stack direction='row' alignItems='center' spacing={1}>
          <Typography>{selectedTransactions.length ? 'Selected Total' : 'Total'}</Typography>
          <Typography variant='h5'>
            {checkpointTransactionTotal !== null ? accountAmountFormatter.format(checkpointTransactionTotal) : 'N/A'}
          </Typography>
        </Stack>
      </Stack>

      <DataGridPro
        loading={!checkpointTransactions}
        rows={checkpointTransactions || []}
        columns={columns}
        pageSizeOptions={[100]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
          sorting: {
            sortModel: [{ field: 'description', sort: 'desc' }],
          },
        }}
        rowSelectionModel={selectedTransactions}
        onRowSelectionModelChange={(rows) => updateSelectedTransactions(rows)}
        style={{ flex: 1 }}
      />
    </Stack>
  );
}

function JournalEntriesTable({ ...props }) {
  const theme = useTheme();
  const {
    account,
    accounts,
    journal,
    toggledOffJournalEntries,
    setJournalEntryToggle,
    checkpointEntries,
    impliedEntries,
    selectedJournalEntries,
    selectedEntriesTotal,
    updateSelectedJournalEntries,
    createJournalEntry,
  } = useContext(ReconciliationContext);

  const [journalEntryLoading, setJournalEntryLoading] = useState(false);
  const [createJournalEntryDialogOpen, setCreateJournalEntryDialogOpen] = useState(false);

  const accountsById = useMemo(() => {
    if (!accounts) {
      return null;
    }

    return accounts.reduce(
      (map, current) => {
        map[current.id] = current;
        return map;
      },
      {} as { [id: string]: Account }
    );
  }, [accounts]);

  const createEntry = useCallback(
    async (entry: CreateJournalEntryArgs) => {
      try {
        setJournalEntryLoading(true);

        await createJournalEntry(entry);

        setCreateJournalEntryDialogOpen(false);
      } finally {
        setJournalEntryLoading(false);
      }
    },
    [createJournalEntry]
  );

  const accountAmountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: account?.externalCurrency || 'CAD' });

  const columns: GridColDef[] = [
    {
      display: 'flex',
      field: 'toggle',
      headerName: '',
      width: 32,
      align: 'center',
      sortable: false,
      renderCell: (params) => {
        const journalEntry = params.row as JournalEntry & { implied?: boolean };
        const txId = journalEntry.implied ? journalEntry.tags.find((t) => t.startsWith('transaction::'))?.split('::')[1] : null;

        return journalEntry.implied ? (
          <Tooltip title={`Implied by unignored transaction ${txId!}`}>
            <MoreCircle size='1.5rem' color={theme.palette.neutral.main} />
          </Tooltip>
        ) : (
          <Switch
            size='small'
            checked={!toggledOffJournalEntries.has(journalEntry.id)}
            onChange={(event) => setJournalEntryToggle(journalEntry.id, event.target.checked)}
          />
        );
      },
    },
    {
      display: 'flex',
      field: 'description',
      headerName: 'Journal Entry',
      flex: 1,
      sortComparator: (_v1, _v2, param1, param2) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        const a = param1.api.getRow(param1.id) as JournalEntry;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        const b = param2.api.getRow(param2.id) as JournalEntry;

        return (a.reconciliationDate || a.date).getTime() - (b.reconciliationDate || b.date).getTime();
      },
      renderCell: (params) => {
        const journalEntry = params.row as JournalEntry;

        return (
          <Box>
            <Typography
              color={toggledOffJournalEntries.has(journalEntry.id) ? theme.palette.text.disabled : undefined}
              style={{
                fontStyle: toggledOffJournalEntries.has(journalEntry.id) ? 'italic' : undefined,
              }}
            >
              {journalEntry.memo}
            </Typography>
            <Typography
              variant='small'
              color={toggledOffJournalEntries.has(journalEntry.id) ? theme.palette.text.disabled : undefined}
              style={{
                fontStyle: toggledOffJournalEntries.has(journalEntry.id) ? 'italic' : undefined,
              }}
            >
              {format(journalEntry.reconciliationDate || journalEntry.date, 'MMM d')}
            </Typography>
          </Box>
        );
      },
    },
    {
      display: 'flex',
      field: 'amount',
      headerName: 'Amount',
      headerAlign: 'right',
      align: 'right',
      sortable: false,
      renderCell: (params) => {
        const journalEntry = params.row as JournalEntry;
        const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: account?.externalCurrency || 'CAD' });

        const debitTypes = new Set([TopLevelAccountType.ASSETS, TopLevelAccountType.EXPENSES, TopLevelAccountType.REVENUE]);

        const amount = [...journalEntry.credits, ...journalEntry.debits].reduce((sum, current) => {
          if (current.accountId !== account?.id) {
            return sum;
          }

          if (
            (debitTypes.has(account.topLevelType) && current.type === JournalEntryLineType.DEBIT) ||
            (!debitTypes.has(account.topLevelType) && current.type === JournalEntryLineType.CREDIT)
          ) {
            return sum + parseFloat(current.amount);
          } else {
            return sum - parseFloat(current.amount);
          }
        }, 0);

        return (
          <Typography
            color={toggledOffJournalEntries.has(journalEntry.id) ? theme.palette.text.disabled : undefined}
            style={{
              fontStyle: toggledOffJournalEntries.has(journalEntry.id) ? 'italic' : undefined,
            }}
          >
            {amountFormatter.format(amount)}
          </Typography>
        );
      },
    },
  ];

  return (
    <Stack spacing={SMALL_VERTICAL_SPACING} {...props}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='h4'>Journal Entries</Typography>

        {journal && accountsById && (
          <>
            <IconButton size='small' onClick={() => setCreateJournalEntryDialogOpen(true)}>
              <AddCircle color={theme.palette.primary.main} size='1rem' />
            </IconButton>

            <JournalEntryDialog
              loading={journalEntryLoading}
              open={createJournalEntryDialogOpen}
              onClose={() => setCreateJournalEntryDialogOpen(false)}
              journal={journal}
              journalAccountsById={accountsById}
              onCreate={createEntry}
            />
          </>
        )}
      </Stack>

      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography>
          {checkpointEntries &&
            `${checkpointEntries.length} items` + (toggledOffJournalEntries.size > 0 ? ` (${toggledOffJournalEntries.size} excluded)` : '')}
        </Typography>

        <Stack direction='row' alignItems='center' spacing={1}>
          <Typography>{selectedJournalEntries.length ? 'Selected Total' : 'Total'}</Typography>
          <Typography variant='h5'>{selectedEntriesTotal !== null ? accountAmountFormatter.format(selectedEntriesTotal) : 'N/A'}</Typography>
        </Stack>
      </Stack>

      <DataGridPro
        loading={!checkpointEntries || !impliedEntries}
        rows={[...(checkpointEntries || []), ...impliedEntries]}
        columns={columns}
        pageSizeOptions={[100]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
          sorting: {
            sortModel: [{ field: 'description', sort: 'desc' }],
          },
        }}
        rowSelectionModel={selectedJournalEntries}
        onRowSelectionModelChange={(rows) => updateSelectedJournalEntries(rows)}
        style={{ flex: 1 }}
      />
    </Stack>
  );
}

const ReconciliationTable = styled(SimpleTable)`
  flex: 1;

  td:first-child {
    width: 64px;
  }

  td,
  th {
    text-align: left;
    padding-left: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
    padding-right: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
    padding-top: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
    padding-bottom: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
  }
`;

const ReconciliationSummaryGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
`;

interface ReconcileDialogProps {
  open: boolean;
  onClose: () => void;
}

function ReconcileDialog({ open, onClose }: ReconcileDialogProps) {
  const [loading, setLoading] = useState(false);

  const {
    toggledOffJournalEntries: toggledOffJournalEntryIds,
    toggledTransactions: toggledTransactionIds,
    journalEntries,
    transactions,
    selectedCheckpoint,
    account,
    checkpointEntriesTotal,
    reconcile,
    impliedEntries,
    runningDifference,
  } = useContext(ReconciliationContext);

  const reconcileAccount = async () => {
    const ignoreTransactionIds = [];
    const unignoreTransactionIds = [];

    for (const [txId, on] of Object.entries(toggledTransactionIds)) {
      if (on) {
        unignoreTransactionIds.push(txId);
      } else {
        ignoreTransactionIds.push(txId);
      }
    }

    try {
      setLoading(true);
      await reconcile({
        ignoreTransactionIds,
        unignoreTransactionIds,
      });
      onClose();
    } finally {
      setLoading(false);
    }
  };

  const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: account?.externalCurrency || 'CAD' });

  const toggledOffJournalEntries = journalEntries?.filter((je) => toggledOffJournalEntryIds.has(je.id)) || [];

  return (
    <Dialog open={open} onClose={onClose} fullWidth style={{}} maxWidth='lg'>
      <DialogTitle>
        <ThreeColumn align='center'>
          <div></div>
          <span>Reconcile</span>
          <IconButton onClick={onClose}>
            <CloseCircle />
          </IconButton>
        </ThreeColumn>
      </DialogTitle>
      <DialogContent>
        <Stack direction='row'>
          <Stack style={{ overflowY: 'auto' }} flex={1}>
            <Stack alignItems='stretch'>
              <Typography variant='h4'>Transactions</Typography>
              <ReconciliationTable>
                <thead>
                  <tr>
                    <th>Change</th>
                    <th>Transaction</th>
                  </tr>
                </thead>
                <tbody>
                  {!Object.keys(toggledTransactionIds).length && (
                    <tr>
                      <td colSpan={2} style={{ textAlign: 'center' }}>
                        None
                      </td>
                    </tr>
                  )}
                  {transactions
                    ? transactions
                        .filter((tx) => toggledTransactionIds[tx.id] === false)
                        .map((tx) => {
                          return (
                            <tr key={tx.id}>
                              <td>Ignore</td>
                              <td>{tx.name}</td>
                            </tr>
                          );
                        })
                    : null}
                  {transactions
                    ? transactions
                        .filter((tx) => toggledTransactionIds[tx.id] === true)
                        .map((tx) => {
                          return (
                            <tr key={tx.id}>
                              <td>Unignore</td>
                              <td>{tx.name}</td>
                            </tr>
                          );
                        })
                    : null}
                </tbody>
              </ReconciliationTable>
            </Stack>

            <Stack>
              <Typography variant='h4'>Journal Entries</Typography>

              <ReconciliationTable>
                <thead>
                  <tr>
                    <th>Change</th>
                    <th>Journal Entry</th>
                  </tr>
                </thead>
                <tbody>
                  {!toggledOffJournalEntries.length && !impliedEntries.length && (
                    <tr>
                      <td colSpan={2} style={{ textAlign: 'center' }}>
                        None
                      </td>
                    </tr>
                  )}
                  {toggledOffJournalEntries
                    .filter((je) => entryIsCurrent(je))
                    .map((je) => {
                      return (
                        <tr key={je.id}>
                          <td>Reverse</td>
                          <td>{je.memo}</td>
                        </tr>
                      );
                    })}
                  {impliedEntries.map((je) => {
                    return (
                      <tr key={je.id}>
                        <td>To be written</td>
                        <td>{je.memo}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </ReconciliationTable>
            </Stack>
          </Stack>

          <Divider orientation='vertical' flexItem />

          <Stack>
            <Typography variant='h3'>Summary</Typography>

            <Stack>
              <Typography variant='h4'>Transactions</Typography>
              <ReconciliationSummaryGrid>
                <Stack spacing={0}>
                  <Typography>Ignored</Typography>
                  <Typography>{Object.values(toggledTransactionIds).filter((t) => t === false).length}</Typography>
                </Stack>

                <Stack spacing={0}>
                  <Typography>Unignored</Typography>
                  <Typography>{Object.values(toggledTransactionIds).filter((t) => t === true).length}</Typography>
                </Stack>
              </ReconciliationSummaryGrid>
            </Stack>

            <Divider />

            <Stack>
              <Typography variant='h4'>Journal Entries</Typography>
              <ReconciliationSummaryGrid>
                <Stack spacing={0}>
                  <Typography>Reversed</Typography>
                  <Typography>{toggledOffJournalEntries.length}</Typography>
                </Stack>
              </ReconciliationSummaryGrid>
            </Stack>

            <Divider />

            <Stack>
              <Typography variant='h4'>Balance</Typography>
              <ReconciliationSummaryGrid>
                <Stack spacing={0} alignItems='end'>
                  <Typography>Previous Journal Balance</Typography>
                  <Typography>
                    {selectedCheckpoint !== null ? amountFormatter.format(parseFloat(selectedCheckpoint.journalBalance)) : null}
                  </Typography>
                </Stack>

                <Stack spacing={0} alignItems='end'>
                  <Typography>New Journal Balance</Typography>
                  <Typography>{amountFormatter.format(checkpointEntriesTotal!)}</Typography>
                </Stack>

                <Stack spacing={0} alignItems='end'>
                  <Typography>Checkpoint</Typography>
                  <Typography>{selectedCheckpoint !== null ? amountFormatter.format(parseFloat(selectedCheckpoint.balance)) : null}</Typography>
                </Stack>

                <Stack spacing={0} alignItems='end'>
                  <Typography>Difference</Typography>
                  <Typography>{runningDifference !== null ? amountFormatter.format(runningDifference) : null}</Typography>
                </Stack>
              </ReconciliationSummaryGrid>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction='row' justifyContent='end'>
          <Button variant='contained' color='neutral' onClick={onClose}>
            Cancel
          </Button>

          <Button variant='contained' color='primary' onClick={reconcileAccount}>
            {loading ? <CircularProgress /> : 'Apply'}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export function ReconciliationAccountView() {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    accounts,
    account,
    journal,
    accountCoverage,
    accountCheckpoints,
    selectedCheckpoint,
    updateSelectedCheckpoint,
    checkpointEntriesTotal,
    toggledOffJournalEntries,
    runningDifference,
    revertToggles,
    impliedEntries,
    checkpointEntries,
  } = useContext(ReconciliationContext);

  const [confirmRevertChangesDialogOpen, setConfirmRevertChangesDialogOpen] = useState(false);
  const [reconcileDialogOpen, setReconcileDialogOpen] = useState(false);

  const revertChanges = () => {
    revertToggles();
    setConfirmRevertChangesDialogOpen(false);
  };

  const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: account?.externalCurrency || 'CAD' });

  if (!accountCoverage || !accountCoverage || !accountCheckpoints || !journal || !account || !accounts) {
    return (
      <Stack justifyContent='center' alignItems='center'>
        <CircularProgress />
      </Stack>
    );
  }

  const cashAndCreditAccounts = accounts.filter((a) =>
    [StandardAccounts.CASH, StandardAccounts.PERSONAL_AND_CREDIT_CARD_LOANS, StandardAccounts.LINE_OF_CREDIT].includes(a.standardAccount)
  );

  const numChanges =
    (checkpointEntries || []).filter((je) => {
      return entryIsCurrent(je) && toggledOffJournalEntries.has(je.id);
    }).length + impliedEntries.length;

  return (
    <Stack flex={1} minHeight={0} spacing={SMALL_VERTICAL_SPACING}>
      <Stack direction='row' alignItems='center' spacing={SMALL_HORIZONTAL_SPACING}>
        <MuiButton
          sx={{
            width: 'auto',
          }}
          variant='text'
          onClick={() => navigate('/admin/reconciliation')}
        >
          <ArrowLeft2 variant='Bold' size='1rem' />
          Back
        </MuiButton>

        <AccountSelect
          accounts={cashAndCreditAccounts}
          selected={account}
          onSelect={(account) => navigate(`/admin/reconciliation/accounts/${account.id}`)}
        />

        <CheckpointSelect
          checkpoints={accountCheckpoints}
          selected={selectedCheckpoint}
          onSelect={(checkpoint) => updateSelectedCheckpoint(checkpoint)}
        />

        <AccountCoverage
          account={account}
          journal={journal}
          coverage={accountCoverage}
          checkpoints={accountCheckpoints}
          selectedCheckpoint={selectedCheckpoint}
          onCheckpointSelect={(checkpoint) => {
            updateSelectedCheckpoint(checkpoint);
          }}
          style={{
            marginTop: 0,
            height: 64,
            flex: 1,
          }}
        />

        <Stack direction='row' spacing={SMALL_HORIZONTAL_SPACING}>
          <Button variant='outlined' color='primary' onClick={() => setConfirmRevertChangesDialogOpen(true)}>
            Revert
          </Button>
          <ConfirmDialog
            open={confirmRevertChangesDialogOpen}
            onClose={() => setConfirmRevertChangesDialogOpen(false)}
            onConfirm={revertChanges}
            onConfirmLabel='Revert'
            message='Are you sure you want to revert changes?'
          />

          <Button variant='contained' color='primary' onClick={() => setReconcileDialogOpen(true)}>
            Reconcile
          </Button>
          <ReconcileDialog open={reconcileDialogOpen} onClose={() => setReconcileDialogOpen(false)} />
        </Stack>
      </Stack>

      <Stack direction='row' justifyContent='stretch' spacing={SMALL_HORIZONTAL_SPACING}>
        <MetricCard
          title='Checkpoint balance'
          primaryContent={
            <Typography variant='h3'>{selectedCheckpoint ? amountFormatter.format(parseFloat(selectedCheckpoint.balance)) : null}</Typography>
          }
          style={{
            flex: 1,
          }}
        />

        <MetricCard
          title='Journal balance'
          primaryContent={
            <Typography variant='h3'>{checkpointEntriesTotal !== null ? amountFormatter.format(checkpointEntriesTotal) : null}</Typography>
          }
          secondaryContent={
            <>
              {selectedCheckpoint &&
                checkpointEntriesTotal !== null &&
                Math.abs(checkpointEntriesTotal - parseFloat(selectedCheckpoint.journalBalance)) > 0.01 && (
                  <Tooltip title={`${amountFormatter.format(parseFloat(selectedCheckpoint.journalBalance) - checkpointEntriesTotal)} toggled off`}>
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Edit2 size='1rem' />
                      <Typography>{amountFormatter.format(checkpointEntriesTotal - parseFloat(selectedCheckpoint.journalBalance))}</Typography>
                    </Stack>
                  </Tooltip>
                )}
            </>
          }
          style={{
            flex: 1,
          }}
        />

        <MetricCard
          title='Difference'
          primaryContent={
            <Typography variant='h3' color={runningDifference === 0 ? undefined : theme.palette.error.main}>
              {runningDifference !== null && runningDifference > 0 ? '+' : ''}
              {runningDifference !== null ? amountFormatter.format(runningDifference) : null}
            </Typography>
          }
          secondaryContent={
            <>
              {checkpointEntriesTotal !== null &&
                runningDifference !== null &&
                selectedCheckpoint?.difference &&
                Math.abs(runningDifference - parseFloat(selectedCheckpoint.difference)) > 0.01 && (
                  <Tooltip title={`${amountFormatter.format(parseFloat(selectedCheckpoint.journalBalance) - checkpointEntriesTotal)} toggled off`}>
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Edit2 size='1rem' />
                      <Typography>{amountFormatter.format(checkpointEntriesTotal - parseFloat(selectedCheckpoint.journalBalance))}</Typography>
                    </Stack>
                  </Tooltip>
                )}
            </>
          }
          style={{
            flex: 1,
          }}
        />

        <MetricCard
          title='Changes pending'
          primaryContent={<Typography variant='h3'>{numChanges}</Typography>}
          style={{
            flex: 1,
          }}
        />
      </Stack>

      <Stack direction='row' flex={1} minHeight={0} spacing={SMALL_HORIZONTAL_SPACING}>
        <TransactionsTable style={{ minWidth: 0, flex: 1 }} />
        <Divider orientation='vertical' />
        <JournalEntriesTable style={{ minWidth: 0, flex: 1 }} />
      </Stack>
    </Stack>
  );
}
