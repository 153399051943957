import '@fontsource/dm-sans';
import '@fontsource/dm-sans/400.css';
import '@fontsource/dm-sans/500.css';
import '@fontsource/dm-sans/600.css';
import '@fontsource/dm-sans/700.css';
import { PaletteMode, alpha } from '@mui/material';
import { BreakpointsOptions, Theme, ThemeOptions } from '@mui/material/styles';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import type {} from '@mui/x-data-grid/themeAugmentation';
import type {} from '@mui/x-date-pickers/themeAugmentation';

const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 480,
    md: 1024,
    lg: 1280,
    xl: 1920,
  },
};

const spacingFn = (factor: number) => {
  return `${0.25 * factor}rem`;
};

const roundedCornerFn = (factor: number) => {
  return `${0.25 * factor}rem`;
};

export const LARGE_HORIZONTAL_SPACING = 9;
export const LARGE_VERTICAL_SPACING = 5;
export const MED_HORIZONTAL_SPACING = 5;
export const MED_VERTICAL_SPACING = 3;
export const SMALL_HORIZONTAL_SPACING = 3;
export const SMALL_VERTICAL_SPACING = 2;

export const themeConfig = (mode: PaletteMode): ThemeOptions => ({
  spacing: spacingFn,
  roundedCorners: roundedCornerFn,
  breakpoints,
  components: {
    MuiAlert: {
      styleOverrides: {
        standardError: ({ theme }: { theme: Theme }) => ({
          border: `1px solid ${theme.palette.error.main}`,
        }),
        standardWarning: ({ theme }: { theme: Theme }) => ({
          border: `1px solid ${theme.palette.warning.main}`,
        }),
        standardSuccess: ({ theme }: { theme: Theme }) => ({
          border: `1px solid ${theme.palette.success.main}`,
        }),
      },
    },
    MuiScopedCssBaseline: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          '.MuiInputBase-input': {
            boxSizing: 'content-box',
          },

          scrollbarColor: theme.palette.mode === 'dark' ? `rgba(255, 255, 255, 0.2) transparent` : `rgba(0, 0, 0, 0.2) transparent`,
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          borderRadius: theme.roundedCorners(3),
          overflow: 'hidden',
          border: `1px solid ${mode === 'dark' ? theme.palette.secondary[300] : theme.palette.neutral[300]}`,
          background: theme.palette.background.paper,
          paddingX: theme.spacing(LARGE_HORIZONTAL_SPACING),
          paddingY: theme.spacing(LARGE_VERTICAL_SPACING),
          [theme.breakpoints.up('sm')]: {
            paddingX: theme.spacing(SMALL_HORIZONTAL_SPACING),
            paddingY: theme.spacing(SMALL_VERTICAL_SPACING),
          },
          [theme.breakpoints.up('md')]: {
            paddingX: theme.spacing(MED_HORIZONTAL_SPACING),
            paddingY: theme.spacing(MED_VERTICAL_SPACING),
          },
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          borderColor: theme.palette.border.main,
        }),
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          '.MuiDayCalendar-weekDayLabel': {
            color: alpha(theme.palette.text.primary, 0.54),
          },
        }),
      },
    },
    MuiDateTimePickerToolbar: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          '.MuiDateTimePickerToolbar-timeDigitsContainer': {
            alignItems: 'center',
          },
          '.MuiTypography-root': {
            color: theme.palette.text.primary,
          },
          '.MuiPickersToolbarText-root': {
            color: alpha(theme.palette.text.primary, 0.5),
          },
          '.MuiPickersToolbarText-root.Mui-selected': {
            color: theme.palette.text.primary,
          },
        }),
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: '2.625rem',
          height: '2.625rem',
          color: 'white',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          colorScheme: theme.palette.mode,
          scrollbarColor: theme.palette.mode === 'dark' ? `rgba(255, 255, 255, 0.2) transparent` : `rgba(0, 0, 0, 0.2) transparent`,
        }),
        paper: ({ theme }: { theme: Theme }) => ({
          borderRadius: theme.roundedCorners(5),
          border: `1px solid ${mode === 'dark' ? theme.palette.secondary[300] : theme.palette.neutral[300]}`,
        }),
      },
    },
    MuiStack: {
      defaultProps: {
        spacing: { xs: SMALL_VERTICAL_SPACING, sm: MED_VERTICAL_SPACING, md: LARGE_VERTICAL_SPACING },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          color: theme.palette.primary.main,
          textDecorationColor: theme.palette.primary.main,
        }),
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          color: alpha(theme.palette.text.primary, 0.6),
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          background: theme.palette.background.default,
        }),
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          paddingTop: `var(--safe-area-inset-top)`,
          paddingBottom: `var(--safe-area-inset-bottom)`,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          colorScheme: theme.palette.mode,
          scrollbarColor: theme.palette.mode === 'dark' ? `rgba(255, 255, 255, 0.2) transparent` : `rgba(0, 0, 0, 0.2) transparent`,
        }),
        paper: ({ theme }: { theme: Theme }) => ({
          borderRadius: theme.roundedCorners(5),
          border: `1px solid ${mode === 'dark' ? theme.palette.secondary[300] : theme.palette.neutral[300]}`,
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          paddingLeft: theme.spacing(MED_HORIZONTAL_SPACING),
          paddingRight: theme.spacing(MED_HORIZONTAL_SPACING),
          paddingTop: `${theme.spacing(MED_VERTICAL_SPACING)} !important`,
          paddingBottom: theme.spacing(MED_VERTICAL_SPACING),
          [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(SMALL_HORIZONTAL_SPACING),
            paddingRight: theme.spacing(SMALL_HORIZONTAL_SPACING),
            paddingTop: `${theme.spacing(SMALL_VERTICAL_SPACING)} !important`,
            paddingBottom: theme.spacing(SMALL_VERTICAL_SPACING),
          },
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          paddingLeft: theme.spacing(MED_HORIZONTAL_SPACING),
          paddingRight: theme.spacing(MED_HORIZONTAL_SPACING),
          paddingTop: `${theme.spacing(MED_VERTICAL_SPACING)} !important`,
          paddingBottom: theme.spacing(MED_VERTICAL_SPACING),
          [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(SMALL_HORIZONTAL_SPACING),
            paddingRight: theme.spacing(SMALL_HORIZONTAL_SPACING),
            paddingTop: `${theme.spacing(SMALL_VERTICAL_SPACING)} !important`,
            paddingBottom: theme.spacing(SMALL_VERTICAL_SPACING),
          },
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          paddingLeft: theme.spacing(MED_HORIZONTAL_SPACING),
          paddingRight: theme.spacing(MED_HORIZONTAL_SPACING),
          paddingTop: theme.spacing(MED_VERTICAL_SPACING),
          paddingBottom: theme.spacing(MED_VERTICAL_SPACING),
          [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(SMALL_HORIZONTAL_SPACING),
            paddingRight: theme.spacing(SMALL_HORIZONTAL_SPACING),
            paddingTop: theme.spacing(SMALL_VERTICAL_SPACING),
            paddingBottom: theme.spacing(SMALL_VERTICAL_SPACING),
          },
        }),
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          boxShadow: theme.shadows[theme.shadows.length - 1],
          borderRadius: theme.roundedCorners(5),

          left: theme.spacing(LARGE_HORIZONTAL_SPACING),
          bottom: `calc(var(--safe-area-inset-bottom) + ${theme.spacing(LARGE_VERTICAL_SPACING)})`,

          [theme.breakpoints.down('sm')]: {
            left: theme.spacing(SMALL_HORIZONTAL_SPACING),
            right: theme.spacing(SMALL_HORIZONTAL_SPACING),
          },
          [theme.breakpoints.down('md')]: {
            left: theme.spacing(MED_HORIZONTAL_SPACING),
            right: theme.spacing(MED_HORIZONTAL_SPACING),
            bottom: `calc(var(--safe-area-inset-bottom) + ${theme.spacing(MED_VERTICAL_SPACING)})`,
          },
        }),
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          borderRadius: theme.roundedCorners(5),
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          background: theme.palette.background2.default,
          color: theme.palette.text.primary,
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
          },
          input: {
            '&:autofill, &:-webkit-autofill, &:-webkit-autofill:focus, &:-webkit-autofill:hover, &:-webkit-autofill:active': {
              backgroundColor: 'rgba(0, 0, 0, 0) !important',
              boxShadow: `0px 0px 0px rgba(0, 0, 0, 0) !important`,
              WebkitBoxShadow: `0px 0px 0px rgba(0, 0, 0, 0) !important`,
              WebkitTextFillColor: `${theme.palette.text.primary} !important`,
              WebkitBackgroundClip: 'text',
            },
          },
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          color: alpha(theme.palette.text.primary, 0.54),
          '&.Mui-focused': {
            color: theme.palette.primary.main, // Custom focus color
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          color: alpha(theme.palette.text.primary, 0.54),
        }),
      },
    },
    MuiTypography: {
      defaultProps: {
        color: 'text.primary',
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          color: theme.palette.text.primary,
        }),
      },
    },
  },
  palette: {
    mode: mode,
    primary: {
      main: mode === 'dark' ? '#4fcf88' : '#00855C',
      900: mode === 'dark' ? '#00855C' : '#074a2a',
      600: mode === 'dark' ? '#2DA463' : '#0b643a',
      500: mode === 'dark' ? '#4fcf88' : '#00855C',
      400: mode === 'dark' ? '#72d9a0' : '#4ca172',
      300: mode === 'dark' ? '#a7e7c3' : '#6dbb8e',
      200: mode === 'dark' ? '#caf1db' : '#a6e2bd',
      100: mode === 'dark' ? '#edfaf3' : '#e3f5e9',
      contrastText: mode === 'dark' ? '#000' : '#fff',
      contrastTextForShade: {
        500: mode === 'dark' ? '#000' : '#fff',
        400: mode === 'dark' ? '#000' : '#fff',
        300: mode === 'dark' ? '#000' : '#fff',
        200: mode === 'dark' ? '#000' : '#fff',
        100: mode === 'dark' ? '#000' : '#fff',
      },
    },
    secondary: {
      main: '#000000',
      500: '#000000',
      400: '#2E2E2E',
      300: '#323232',
      200: '#686868',
      100: '#BCBCBC',
      contrastText: '#fff',
      contrastTextForShade: {
        500: '#fff',
        400: '#fff',
        300: '#fff',
        200: '#000',
        100: '#000',
      },
    },
    neutral: {
      main: '#C1C1C1',
      500: '#C1C1C1',
      400: '#CDCDCD',
      300: '#EEEEEE',
      200: '#FFFFFF',
      100: '#FFFFFF',
      contrastText: '#000',
      contrastTextForShade: {
        500: '#000',
        400: '#000',
        300: '#000',
        200: '#000',
        100: '#000',
      },
    },
    accent1: {
      main: '#7F63FC',
      500: '#7F63FC',
      400: '#9982FD',
      300: '#B2A1FD',
      200: '#CCC1FE',
      100: '#F2EFFF',
      contrastText: '#000',
      contrastTextForShade: {
        500: '#000',
        400: '#000',
        300: '#000',
        200: '#000',
        100: '#000',
      },
    },
    accent2: {
      main: '#EF6E33',
      500: '#EF6E33',
      400: '#FE9A6D',
      300: '#FEB797',
      200: '#FAD4C2',
      100: '#FFE3D7',
      contrastText: '#000',
      contrastTextForShade: {
        500: '#000',
        400: '#000',
        300: '#000',
        200: '#000',
        100: '#000',
      },
    },
    error: {
      main: '#b00020',
      900: '#b00020',
      800: '#bf152c',
      700: '#cc1d33',
      600: '#de2839',
      500: '#ed323b',
      400: '#e84853',
      300: '#de6c74',
      200: '#ea959b',
      100: '#fbcad2',
      contrastText: '#fff',
    },
    success: {
      main: '#8AC99D',
      900: '#0e6410',
      800: '#298324',
      700: '#35942f',
      600: '#41a63a',
      500: '#4bb543',
      400: '#66c060',
      300: '#82cc7d',
      200: '#a6d9a3',
      100: '#c9e8c7',
      contrastText: '#fff',
    },
    border: {
      main: mode === 'dark' ? '#323232' : '#C1C1C1',
    },
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      default: mode === 'dark' ? '#131313' : '#f9f9f9', // Used by css baseline to set body bg color
      paper: mode === 'dark' ? '#131313' : '#fcfcfc',
    },
    background2: {
      default: mode === 'dark' ? '#101010' : '#fcfcfc',
    },
    text: {
      primary: mode === 'dark' ? '#cacaca' : '#313131',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: mode === 'dark' ? 'rgba(255, 255, 255, 0.38)' : 'rgba(0, 0, 0, 0.38)',
    },
    contrastThreshold: 4.5,
    tonalOffset: 0.2,
  },
  // box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.12);
  shadows: [
    'none',
    '0px 2px 3px -1px rgba(0,0,0,0.05),0px 1px 3px 0px rgba(0,0,0,0.08),0px 1px 5px 0px rgba(0,0,0,0.04)',
    '0px 3px 3px -2px rgba(0,0,0,0.05),0px 2px 4px 0px rgba(0,0,0,0.08),0px 1px 7px 0px rgba(0,0,0,0.04)',
    '0px 3px 5px -2px rgba(0,0,0,0.05),0px 3px 6px 0px rgba(0,0,0,0.08),0px 1px 10px 0px rgba(0,0,0,0.04)',
    '0px 2px 6px -1px rgba(0,0,0,0.05),0px 4px 7px 0px rgba(0,0,0,0.08),0px 1px 12px 0px rgba(0,0,0,0.04)',
    '0px 3px 7px -1px rgba(0,0,0,0.05),0px 5px 10px 0px rgba(0,0,0,0.08),0px 1px 16px 0px rgba(0,0,0,0.04)',
    '0px 3px 7px -1px rgba(0,0,0,0.05),0px 6px 12px 0px rgba(0,0,0,0.08),0px 1px 20px 0px rgba(0,0,0,0.04)',
    '0px 4px 7px -2px rgba(0,0,0,0.05),0px 7px 12px 1px rgba(0,0,0,0.08),0px 2px 18px 1px rgba(0,0,0,0.04)',
    '0px 5px 7px -3px rgba(0,0,0,0.05),0px 8px 12px 1px rgba(0,0,0,0.08),0px 3px 18px 2px rgba(0,0,0,0.04)',
    '0px 5px 8px -3px rgba(0,0,0,0.05),0px 9px 14px 1px rgba(0,0,0,0.08),0px 3px 18px 2px rgba(0,0,0,0.04)',
    '0px 6px 8px -3px rgba(0,0,0,0.05),0px 10px 16px 1px rgba(0,0,0,0.08),0px 4px 20px 3px rgba(0,0,0,0.04)',
    '0px 6px 9px -4px rgba(0,0,0,0.05),0px 11px 17px 1px rgba(0,0,0,0.08),0px 4px 22px 3px rgba(0,0,0,0.04)',
    '0px 7px 10px -4px rgba(0,0,0,0.05),0px 12px 19px 2px rgba(0,0,0,0.08),0px 5px 24px 4px rgba(0,0,0,0.04)',
    '0px 7px 10px -4px rgba(0,0,0,0.05),0px 13px 21px 2px rgba(0,0,0,0.08),0px 5px 26px 4px rgba(0,0,0,0.04)',
    '0px 7px 11px -4px rgba(0,0,0,0.05),0px 14px 23px 2px rgba(0,0,0,0.08),0px 5px 28px 4px rgba(0,0,0,0.04)',
    '0px 8px 11px -5px rgba(0,0,0,0.05),0px 15px 24px 2px rgba(0,0,0,0.08),0px 6px 30px 5px rgba(0,0,0,0.04)',
    '0px 8px 12px -5px rgba(0,0,0,0.05),0px 16px 26px 2px rgba(0,0,0,0.08),0px 6px 32px 5px rgba(0,0,0,0.04)',
    '0px 8px 13px -5px rgba(0,0,0,0.05),0px 17px 28px 2px rgba(0,0,0,0.08),0px 6px 34px 5px rgba(0,0,0,0.04)',
    '0px 9px 13px -5px rgba(0,0,0,0.05),0px 18px 30px 2px rgba(0,0,0,0.08),0px 7px 36px 6px rgba(0,0,0,0.04)',
    '0px 9px 14px -6px rgba(0,0,0,0.05),0px 19px 31px 2px rgba(0,0,0,0.08),0px 7px 38px 6px rgba(0,0,0,0.04)',
    '0px 10px 15px -6px rgba(0,0,0,0.05),0px 20px 33px 3px rgba(0,0,0,0.08),0px 8px 40px 7px rgba(0,0,0,0.04)',
    '0px 10px 15px -6px rgba(0,0,0,0.05),0px 21px 35px 3px rgba(0,0,0,0.08),0px 8px 42px 7px rgba(0,0,0,0.04)',
    '0px 10px 16px -6px rgba(0,0,0,0.05),0px 22px 37px 3px rgba(0,0,0,0.08),0px 8px 44px 7px rgba(0,0,0,0.04)',
    '0px 11px 16px -7px rgba(0,0,0,0.05),0px 23px 38px 3px rgba(0,0,0,0.08),0px 9px 46px 8px rgba(0,0,0,0.04)',
    '0px 11px 17px -7px rgba(0,0,0,0.05),0px 24px 40px 3px rgba(0,0,0,0.08),0px 9px 48px 8px rgba(0,0,0,0.04)',
  ],
  typography: {
    fontFamily: 'DM SANS, sans-serif',
    h1: {
      fontSize: '2rem',
      fontWeight: 500,
      [`@media (max-width:${breakpoints.values!['md']}px)`]: {
        fontSize: '1.766rem',
      },
    },
    h2: {
      fontSize: '1.33rem',
      [`@media (max-width:${breakpoints.values!['md']}px)`]: {
        fontSize: '1.11rem',
      },
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.11rem',
      [`@media (max-width:${breakpoints.values!['md']}px)`]: {
        fontSize: '1rem',
      },
      fontWeight: 500,
    },
    h4: {
      fontSize: '1rem',
      [`@media (max-width:${breakpoints.values!['md']}px)`]: {
        fontSize: '0.777rem',
      },
      fontWeight: 400,
    },
    h5: {
      fontSize: '0.777rem',
      [`@media (max-width:${breakpoints.values!['md']}px)`]: {
        fontSize: '0.6rem',
      },
      fontWeight: 600,
    },
    body1: {
      fontSize: '0.888rem',
      [`@media (max-width:${breakpoints.values!['md']}px)`]: {
        fontSize: '.777rem',
      },
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.777rem',
      [`@media (max-width:${breakpoints.values!['md']}px)`]: {
        fontSize: '0.666rem',
      },
      fontWeight: 400,
    },
    button: {
      fontSize: '0.888rem',
      [`@media (max-width:${breakpoints.values!['md']}px)`]: {
        fontSize: '0.777rem',
      },
      fontWeight: 600,
      textTransform: 'none',
      lineHeight: 'normal',
    },
    button2: {
      fontSize: '0.777rem',
      [`@media (max-width:${breakpoints.values!['md']}px)`]: {
        fontSize: '0.666rem',
      },
      fontWeight: 600,
      textTransform: 'none',
    },
    small: {
      fontSize: '0.666rem',
      [`@media (max-width:${breakpoints.values!['md']}px)`]: {
        fontSize: '0.5rem',
      },
      fontWeight: 400,
    },
  },
});
