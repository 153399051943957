import { alpha, Box, Tooltip, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { LocationCross, LocationTick } from 'iconsax-react';
import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Account, Checkpoint, Journal } from '../../../api';

function findCoverageAndGaps(
  start: Date,
  end: Date,
  coverage: Array<{
    start: Date;
    end: Date;
  }>,
  _firstUnreconciledCheckpoint: Checkpoint | null
) {
  // Sort the coverage array by start date
  const sortedCoverage = coverage.sort((a, b) => a.start.getTime() - b.start.getTime());

  const result = [];
  let currentDate = start;

  for (const range of sortedCoverage) {
    // Check for a gap before the current range
    if (currentDate < range.start) {
      result.push({
        type: 'gap',
        start: currentDate,
        end: new Date(range.start.getTime() - 1), // One millisecond before the next coverage starts
      });
    }

    // Add the coverage range
    result.push({
      type: 'coverage',
      start: range.start,
      end: range.end,
    });

    // Update the current date
    currentDate = range.end > currentDate ? range.end : currentDate;
  }

  // Check for a gap after the last coverage range
  if (currentDate < end) {
    result.push({
      type: 'gap',
      start: currentDate,
      end: end,
    });
  }

  return result;
}

function getPeriodPercentOfFy(fyStart: Date, fyEnd: Date, period: { start: Date; end: Date }) {
  const fyLength = fyEnd.getTime() - fyStart.getTime();
  const periodLength = period.end.getTime() - period.start.getTime();

  return periodLength / fyLength;
}

function getCheckpointPercentOfFy(fyStart: Date, fyEnd: Date, checkpointDate: Date) {
  const fyLength = fyEnd.getTime() - fyStart.getTime();
  const checkpointX = checkpointDate.getTime() - fyStart.getTime();
  return checkpointX / fyLength;
}

const CoveragePeriod = styled.div<{ width: number; type: string }>`
  display: inline-block;
  width: ${({ width }) => `${width}%`};
  height: 100%;
  background: ${({ theme, type }) =>
    `${type === 'gap' ? alpha(theme.palette.warning.main, 0.75) : type === 'error' ? alpha(theme.palette.error.main, 0.75) : alpha(theme.palette.primary.main, 0.75)}`};
  transition: all 100ms ease-out;

  &:hover {
    background: ${({ theme, type }) =>
      `${type === 'gap' ? alpha(theme.palette.warning.main, 1) : type === 'error' ? alpha(theme.palette.error.main, 0.75) : alpha(theme.palette.primary.main, 1)}`};
  }
`;

const CheckpointIndicator = styled(
  ({
    selected,
    onSelect,
    checkpoint,
    ...props
  }: {
    checkpoint: {
      date: Date;
      journalBalance: string;
      balance: string;
      reconciled: boolean;
    };
    selected: boolean;
    onSelect: () => void;
  }) => {
    const theme = useTheme();

    let selectedColor: string;
    if (theme.palette.mode === 'dark') {
      selectedColor = '#000';
    } else {
      selectedColor = '#fff';
    }

    if (checkpoint.reconciled) {
      return (
        <LocationTick
          size={16}
          variant='Bold'
          color={selected ? selectedColor : theme.palette.primary.main}
          style={{
            stroke: selected ? theme.palette.primary.main : undefined,
            strokeWidth: selected ? '1px' : undefined,
          }}
          onClick={onSelect}
          {...props}
        />
      );
    } else {
      return (
        <LocationCross
          size={16}
          variant='Bold'
          color={selected ? selectedColor : theme.palette.error.main}
          style={{
            stroke: selected ? theme.palette.error.main : undefined,
            strokeWidth: selected ? '1px' : undefined,
          }}
          onClick={onSelect}
          {...props}
        />
      );
    }
  }
)`
  transition: all 100ms ease-out;

  &:hover {
    transform: scale(125%);
    cursor: pointer;
  }
`;

export interface AccountCoverageProps extends HTMLAttributes<HTMLDivElement> {
  journal: Journal;
  account: Account;
  checkpoints: Array<Checkpoint>;
  coverage: Array<{
    start: Date;
    end: Date;
  }>;
  selectedCheckpoint: Checkpoint | null;
  onCheckpointSelect: (checkpoint: Checkpoint) => void;
}

export function AccountCoverage({ journal, account, checkpoints, coverage, selectedCheckpoint, onCheckpointSelect, ...props }: AccountCoverageProps) {
  const theme = useTheme();

  const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: account.externalCurrency || 'CAD' });

  const firstUnreconciledCheckpoint = checkpoints.find((c) => !c.reconciled) || null;
  const coveragePeriods = findCoverageAndGaps(journal.fyStart, journal.fyEnd, coverage, firstUnreconciledCheckpoint);

  return (
    <Box display='flex' flexDirection='column' {...props}>
      <Box flexShrink={0} position='relative' height={24}>
        {checkpoints.map((checkpoint, i) => (
          <Tooltip
            title={
              <>
                <div>{`Checkpoint: ${format(checkpoint.date, 'MMM d')}`}</div>
                <div>{`Checkpoint balance: ${amountFormatter.format(parseFloat(checkpoint.balance))}`}</div>
                <div>{`Journal balance: ${amountFormatter.format(parseFloat(checkpoint.journalBalance))}`}</div>
              </>
            }
            key={`checkpoint-${i}`}
          >
            <Box
              position='absolute'
              top='50%'
              left={`${100 * getCheckpointPercentOfFy(journal.fyStart, journal.fyEnd, checkpoint.date)}%`}
              lineHeight='16px'
              style={{
                transform: `translate(-50%, -50%)`,
                zIndex: checkpoint.reconciled ? undefined : 1,
              }}
            >
              <CheckpointIndicator
                selected={selectedCheckpoint?.id === checkpoint.id}
                onSelect={() => {
                  onCheckpointSelect(checkpoint);
                }}
                checkpoint={checkpoint}
              />
            </Box>
          </Tooltip>
        ))}
      </Box>
      <Box flex={1} borderRadius={theme.roundedCorners(5)} overflow='hidden'>
        {coveragePeriods.map((period, i) => (
          <Tooltip
            title={`${period.type === 'gap' ? 'Coverage gap:' : 'Coverage:'} ${format(period.start, 'MMM d')} - ${format(period.end, 'MMM d')}`}
            key={`period-${i}`}
          >
            <CoveragePeriod width={100 * getPeriodPercentOfFy(journal.fyStart, journal.fyEnd, period)} type={period.type}></CoveragePeriod>
          </Tooltip>
        ))}
      </Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between' lineHeight='24px' height={24}>
        <Typography variant='small'>{format(journal.fyStart, 'MMM d')}</Typography>
        <Typography variant='small'>{format(journal.fyEnd, 'MMM d')}</Typography>
      </Box>
    </Box>
  );
}
