import { Check } from '@mui/icons-material';
import { Fade, Theme, styled, useTheme } from '@mui/material';
import { CSSProperties } from 'react';

export interface GamifiedProgressBarProps {
  width: number;
  complete: number;
  total: number;
  style?: CSSProperties;
}

interface CompletionCircleProps {
  theme: Theme;
  transformOrigin: { x: string; y: string };
  className?: string;
}
const CompletionCircle = styled('circle', { shouldForwardProp: (prop: string) => prop !== 'transformOrigin' })(
  ({ theme, transformOrigin }: CompletionCircleProps) => ({
    transformOrigin: `${transformOrigin.x} ${transformOrigin.y}`,
    '@keyframes complete': {
      from: {
        transform: 'scale(120%)',
        fill: '#fff',
      },
      to: {
        transform: 'scale(100%)',
        fill: theme.palette.primary.main,
      },
    },
    '&.complete': {
      animation: `complete 1s ease-out forwards`,
    },
  })
);

export function GamifiedProgressBar({ width, complete, total }: GamifiedProgressBarProps) {
  const theme = useTheme();
  const fillAmount = Math.max(0.1, complete / total);

  const paddingPx = 10;
  const componentHeightRems = 3;
  const circleMaskRadiusRems = 1.6;
  const outerCircleRadiusRems = 1.5;
  const innerCircleRadiusRems = 1.1;
  const checkSize = 1.5;
  const barHeight = 1.5;

  const remToPixels = (function () {
    const remValuePx = parseFloat(getComputedStyle(document.documentElement).fontSize);
    return (rem: number) => {
      return rem * remValuePx;
    };
  })();

  const pathD = [
    `M ${paddingPx},${paddingPx + remToPixels(componentHeightRems) / 2}`,
    `A ${remToPixels(barHeight) / 2},${remToPixels(barHeight) / 2} 0 0,1 ${paddingPx + remToPixels(barHeight) / 2},${
      remToPixels(componentHeightRems) / 2 - remToPixels(barHeight) / 2 + paddingPx
    }`,
    `L ${width - remToPixels(circleMaskRadiusRems) * 2 - paddingPx}, ${
      remToPixels(componentHeightRems) / 2 - remToPixels(barHeight) / 2 + paddingPx
    }`,
    `A ${remToPixels(circleMaskRadiusRems)},${remToPixels(circleMaskRadiusRems)} 0 0,0 ${width - remToPixels(circleMaskRadiusRems) * 2 - paddingPx},${
      remToPixels(componentHeightRems) / 2 + remToPixels(barHeight) / 2 + paddingPx
    }`,
    `L ${paddingPx + remToPixels(barHeight) / 2}, ${remToPixels(componentHeightRems) / 2 + remToPixels(barHeight) / 2 + paddingPx}`,
    `A ${remToPixels(barHeight) / 2},${remToPixels(barHeight) / 2} 0 0,1 ${paddingPx},${remToPixels(componentHeightRems) / 2 + paddingPx}`,
    `Z`,
  ].join(' ');

  return (
    <svg width={width} height={remToPixels(componentHeightRems) + paddingPx * 2} xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <mask id='bar-mask'>
          <path d={pathD} stroke='#fff' strokeWidth='1' fill='#fff' />
        </mask>
        <mask id='progress-mask'>
          <rect
            x={paddingPx}
            y={remToPixels(componentHeightRems) / 2 - remToPixels(barHeight) / 2 + paddingPx}
            width={width - 2 * paddingPx}
            height={`${barHeight}rem`}
            rx={`${barHeight / 2}rem`}
            ry={`${barHeight / 2}rem`}
            fill='#fff'
            style={{
              transform: `translateX(${-100 + fillAmount * 100}%)`,
              transition: 'transform 0.5s',
            }}
          />
        </mask>
      </defs>
      <g mask='url(#bar-mask)'>
        <path d={pathD} fill='#f9f9f9' />
        <rect
          x={paddingPx}
          y={remToPixels(componentHeightRems) / 2 - remToPixels(barHeight) / 2 + paddingPx}
          width={width - 2 * paddingPx}
          height={`${barHeight}rem`}
          rx={`${barHeight / 2}rem`}
          ry={`${barHeight / 2}rem`}
          fill={theme.palette.primary.main}
          style={{
            transform: `translateX(${-100 + fillAmount * 100}%)`,
            transition: 'transform 0.5s',
          }}
        />
        {theme.palette.mode === 'dark' ? null : <path stroke={theme.palette.neutral.main} strokeWidth='1' d={pathD} fill='none' />}
        {complete >= total ? (
          <Fade in={true} timeout={1000}>
            <text
              x='50%'
              y='50%'
              textAnchor='middle'
              fill={theme.palette.mode === 'dark' ? '#000' : '#fff'}
              fontWeight='700'
              dominantBaseline='central'
            >
              Complete
            </text>
          </Fade>
        ) : (
          <>
            {theme.palette.mode === 'dark' ? (
              <text x='50%' y='50%' textAnchor='middle' fill='#000' fontWeight='700' dominantBaseline='central'>
                {complete} / {total}
              </text>
            ) : (
              <>
                <text x='50%' y='50%' textAnchor='middle' fill='#000' fontWeight='700' dominantBaseline='central'>
                  {complete} / {total}
                </text>
                <text x='50%' y='50%' textAnchor='middle' fill='#fff' fontWeight='700' dominantBaseline='central' mask='url(#progress-mask)'>
                  {complete} / {total}
                </text>
              </>
            )}
          </>
        )}
      </g>
      <circle
        cx={width - remToPixels(outerCircleRadiusRems) - paddingPx}
        cy='50%'
        r={`${outerCircleRadiusRems}rem`}
        fill={theme.palette.warning.main}
      />
      <CompletionCircle
        transformOrigin={{
          x: `${width - remToPixels(outerCircleRadiusRems)}px`,
          y: '50%',
        }}
        cx={width - remToPixels(outerCircleRadiusRems) - paddingPx}
        cy='50%'
        r={complete >= total ? `${outerCircleRadiusRems}rem` : `${innerCircleRadiusRems}rem`}
        fill='#f9f9f9'
        className={complete === total ? 'complete' : ''}
      />
      <Check
        x={width - remToPixels(outerCircleRadiusRems) - remToPixels(checkSize) / 2 - paddingPx}
        y={remToPixels(componentHeightRems) / 2 - remToPixels(checkSize) / 2 + paddingPx}
        width={`${checkSize}rem`}
        height={`${checkSize}rem`}
        style={{
          fill: complete >= total ? '#fff' : theme.palette.warning.main,
          stroke: complete >= total ? '#fff' : theme.palette.warning.main,
        }}
        strokeWidth='2'
      />
    </svg>
  );
}
