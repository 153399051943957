import { FormHelperText, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { appEnv, useConversation, useOrganization, useSession } from '../../api';
import { Button } from '../button';
import { IWidgetProps } from './IWidgetProps';

export const DocumentEmailAddressWidget = ({ isSuccess }: IWidgetProps) => {
  const { fetchOrganization } = useSession();
  const { checkDocumentEmailAvailability } = useOrganization();
  const { addConversationMessage, conversationId } = useConversation();
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(isSuccess === true);
  const [alias, setAlias] = useState('');
  const [valid, setValid] = useState<boolean | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const emailPrefix = appEnv === 'app' ? 'documents+' : 'documents-dev+';
  const emailPrefixRegex = new RegExp(appEnv === 'app' ? /^documents\+/ : /^documents-dev\+/);
  const fullEmailRegex = new RegExp(appEnv === 'app' ? /^documents\+[a-zA-Z0-9]+@otter.ca$/ : /^documents-dev\+[a-zA-Z0-9]+@otter.ca$/);
  const fullEmail = `${emailPrefix}${alias}@otter.ca`;

  const debouncedCheckAvailability = useDebouncedCallback(async (email: string) => {
    return await checkDocumentEmailAvailability(email);
  }, 500);

  useEffect(() => {
    if (alias && debouncedCheckAvailability) {
      const result = debouncedCheckAvailability(fullEmail);

      if (result) {
        result
          .then((available) => {
            console.log(available);
            setValid(available);
          })
          .catch((e) => {
            throw e;
          });
      }
    }
  }, [alias, debouncedCheckAvailability, fullEmail]);

  const handleCursorPosition = () => {
    if (inputRef.current) {
      const currentPos = inputRef.current.selectionStart || 0;

      // Calculate bounds of alias
      const startAliasPos = emailPrefix.length; // Length of "documents+"
      const endAliasPos = startAliasPos + alias.length;

      // If cursor is outside the alias part, reposition it
      if (currentPos < startAliasPos) {
        inputRef.current.setSelectionRange(startAliasPos, startAliasPos);
      } else if (currentPos > endAliasPos) {
        inputRef.current.setSelectionRange(endAliasPos, endAliasPos);
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue.length < emailPrefix.length + '@otter.ca'.length) {
      return;
    }

    // Remove prefix and suffix from the input to extract the alias
    if (inputValue.startsWith(emailPrefix) && inputValue.endsWith('@otter.ca')) {
      const aliasPart = inputValue.replace(emailPrefixRegex, '').replace(/@otter\.ca$/, '');
      setAlias(aliasPart); // Update only the alias part
    } else if (!inputValue.startsWith(emailPrefix)) {
      let found = false;
      for (let i = 0; i < emailPrefix.length; i++) {
        if (emailPrefix.charAt(i) !== inputValue.charAt(i)) {
          found = true;
          setAlias((alias) => inputValue.charAt(i) + alias);
          break;
        }
      }

      if (!found) {
        setAlias((alias) => inputValue.charAt(emailPrefix.length) + alias);
      }

      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.setSelectionRange(emailPrefix.length, emailPrefix.length);
        }
      }, 0);
    } else {
      let found = false;
      for (let i = 0; i < '@otter.ca'.length; i++) {
        if ('@otter.ca'.charAt(i) !== inputValue.charAt(i + emailPrefix.length + alias.length)) {
          found = true;
          setAlias((alias) => alias + inputValue.charAt(i + emailPrefix.length + alias.length));
          break;
        }
      }

      if (!found) {
        setAlias((alias) => alias + inputValue.charAt(inputValue.indexOf('@otter.ca') + '@otter.ca'.length));
      }

      setTimeout(() => {
        const index = inputValue.indexOf('@') + 1;
        if (inputRef.current) {
          inputRef.current.setSelectionRange(index, index);
        }
      }, 0);
    }
  };

  const submit = async () => {
    try {
      setLoading(true);

      await addConversationMessage({
        conversationId: conversationId!,
        message: JSON.stringify({
          documentEmailAddress: fullEmail,
        }),
        includeMessageInConversation: false,
      });

      await fetchOrganization();

      setComplete(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack alignItems='start'>
      <Typography variant='h4'>Document Email Address</Typography>
      <TextField
        inputRef={inputRef}
        value={fullEmail}
        onChange={handleChange}
        placeholder={`${emailPrefix}<alias>@otter.ca`}
        fullWidth
        helperText={
          <FormHelperText error={valid === false}>
            {valid === false ? 'This address is in use' : `Email format: ${emailPrefix}<alias>@otter.ca`}
          </FormHelperText>
        }
        onClick={handleCursorPosition}
        onKeyUp={handleCursorPosition}
        disabled={loading || complete}
      />

      <Button variant='contained' color='primary' disabled={loading || complete || !fullEmailRegex.test(fullEmail)} onClick={submit}>
        Ok
      </Button>
    </Stack>
  );
};
