export enum StatementType {
  AMEX_CAD = 'AMEX_CAD',
  AMEX_USD = 'AMEX_USD',
  BMO_PDF = 'BMO_PDF',
  CIBC_PDF = 'CIBC_PDF',
  CIBC_VISA_PDF = 'CIBC_VISA_PDF',
  RBC_CANADA = 'RBC_CANADA',
  RBC_US = 'RBC_US',
  RBC_VISA_USD = 'RBC_VISA_USD',
  RBC_VISA_CAD = 'RBC_VISA_CAD',
  RBC_OPERATING_LINE = 'RBC_OPERATING_LINE',
  LOOP = 'LOOP',
  LOOP_ACCOUNT = 'LOOP_ACCOUNT',
  LOOP_CARD = 'LOOP_CARD',
  WISE = 'WISE',
  WEALTHSIMPLE = 'WEALTHSIMPLE',
  FLOAT_PDF = 'FLOAT_PDF',
  FLOAT_CSV = 'FLOAT_CSV',
  OTTER_CSV = 'OTTER_CSV',
  STRIPE_CSV = 'STRIPE_CSV',
  VAULT_CSV = 'VAULT_CSV',
}

export enum Readiness {
  EXPERIMENTAL = 'Experimental',
  READY = 'Ready',
}

export const StatementTypeToMimeType = {
  [StatementType.BMO_PDF]: 'application/pdf',
  [StatementType.RBC_CANADA]: 'application/pdf',
  [StatementType.RBC_US]: 'application/pdf',
  [StatementType.RBC_VISA_CAD]: 'application/pdf',
  [StatementType.RBC_VISA_USD]: 'application/pdf',
  [StatementType.RBC_OPERATING_LINE]: 'application/pdf',
  [StatementType.AMEX_CAD]: 'application/pdf',
  [StatementType.AMEX_USD]: 'application/pdf',
  [StatementType.CIBC_PDF]: 'application/pdf',
  [StatementType.CIBC_VISA_PDF]: 'application/pdf',
  [StatementType.LOOP]: 'text/csv',
  [StatementType.LOOP_ACCOUNT]: 'application/pdf',
  [StatementType.WISE]: 'text/csv',
  [StatementType.WEALTHSIMPLE]: 'application/pdf',
  [StatementType.FLOAT_PDF]: 'application/pdf',
  [StatementType.LOOP_CARD]: 'text/csv',
  [StatementType.FLOAT_CSV]: 'text/csv',
  [StatementType.STRIPE_CSV]: 'text/csv',
  [StatementType.OTTER_CSV]: 'text/csv',
  [StatementType.VAULT_CSV]: 'text/csv',
};

export const StatementTypeReadiness = {
  [StatementType.BMO_PDF]: Readiness.EXPERIMENTAL,
  [StatementType.AMEX_CAD]: Readiness.EXPERIMENTAL,
  [StatementType.AMEX_USD]: Readiness.EXPERIMENTAL,
  [StatementType.RBC_CANADA]: Readiness.EXPERIMENTAL,
  [StatementType.RBC_US]: Readiness.EXPERIMENTAL,
  [StatementType.RBC_VISA_USD]: Readiness.EXPERIMENTAL,
  [StatementType.RBC_VISA_CAD]: Readiness.EXPERIMENTAL,
  [StatementType.RBC_OPERATING_LINE]: Readiness.EXPERIMENTAL,
  [StatementType.LOOP]: Readiness.EXPERIMENTAL,
  [StatementType.LOOP_ACCOUNT]: Readiness.EXPERIMENTAL,
  [StatementType.LOOP_CARD]: Readiness.EXPERIMENTAL,
  [StatementType.WISE]: Readiness.EXPERIMENTAL,
  [StatementType.WEALTHSIMPLE]: Readiness.EXPERIMENTAL,
  [StatementType.FLOAT_PDF]: Readiness.EXPERIMENTAL,
  [StatementType.FLOAT_CSV]: Readiness.EXPERIMENTAL,
  [StatementType.OTTER_CSV]: Readiness.READY,
  [StatementType.STRIPE_CSV]: Readiness.EXPERIMENTAL,
  [StatementType.VAULT_CSV]: Readiness.EXPERIMENTAL,
  [StatementType.CIBC_PDF]: Readiness.EXPERIMENTAL,
  [StatementType.CIBC_VISA_PDF]: Readiness.EXPERIMENTAL,
};
