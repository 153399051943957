import { ListItemText, MenuItem, Stack } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import {
  ArrowSwapHorizontal,
  BackwardItem,
  Book,
  Briefcase,
  Calculator,
  Calendar,
  ClipboardTick,
  Coin,
  DocumentText1,
  Logout,
  Message,
  MoneySend,
  People,
  TableDocument,
} from 'iconsax-react';
import { MouseEvent, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useSession } from '../api';
import { MED_VERTICAL_SPACING, SMALL_HORIZONTAL_SPACING } from '../theme';
import AppShell, { NavLink as AppShellNavLink } from './app-shell';
import { AvatarMenu } from './app-shell-header';

const NavLink = styled(AppShellNavLink)`
  padding-top: ${({ theme }) => theme.spacing(MED_VERTICAL_SPACING)};
  padding-bottom: ${({ theme }) => theme.spacing(MED_VERTICAL_SPACING)};
`;

export interface AdminAppShellProps {
  children: React.ReactNode;
}

export function AdminAppShell({ children }: AdminAppShellProps) {
  const theme = useTheme();

  const location = useLocation();
  const [flyoutOpen, setFlyoutOpen] = useState(false);
  const { session, logout } = useSession();

  const navLinkClose = (e: MouseEvent) => {
    if ((e.target as HTMLDivElement).classList.contains('MuiLink-root')) {
      setFlyoutOpen(false);
    }
  };

  return (
    <AppShell
      compact={true}
      flyoutOpen={flyoutOpen}
      onFlyoutChange={(open) => setFlyoutOpen(open)}
      authenticatedHeaderContent={
        session && (
          <AvatarMenu
            size='small'
            session={session}
            menuItems={(closeMenu) => {
              return [
                <MenuItem
                  onClick={async () => {
                    closeMenu();
                    await logout();
                  }}
                >
                  <Logout style={{ marginRight: theme.spacing(5) }} />
                  <ListItemText>Logout</ListItemText>
                </MenuItem>,
              ];
            }}
          />
        )
      }
      leftNavContent={
        <Stack
          flex={1}
          marginTop={theme.spacing(5)}
          justifyContent='space-between'
          style={{
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          <Stack spacing={0} alignItems='stretch' onClick={navLinkClose}>
            <NavLink to='/admin/journals' active={location.pathname.startsWith('/admin/journals')}>
              <Book size='1.1rem' style={{ flexShrink: 0, marginRight: theme.spacing(SMALL_HORIZONTAL_SPACING) }} />
              Journals
            </NavLink>
            <NavLink to='/admin/reconciliation' active={location.pathname.startsWith('/admin/reconciliation')}>
              <ClipboardTick size='1.1rem' style={{ flexShrink: 0, marginRight: theme.spacing(SMALL_HORIZONTAL_SPACING) }} />
              Reconciliation
            </NavLink>
            <NavLink to='/admin/calculations' active={location.pathname.startsWith('/admin/calculations')}>
              <Calculator size='1.1rem' style={{ flexShrink: 0, marginRight: theme.spacing(SMALL_HORIZONTAL_SPACING) }} />
              Calculations
            </NavLink>
            <NavLink to='/admin/transactions' active={location.pathname.startsWith('/admin/transactions')}>
              <ArrowSwapHorizontal size='1.1rem' style={{ flexShrink: 0, marginRight: theme.spacing(SMALL_HORIZONTAL_SPACING) }} />
              Transactions
            </NavLink>
            <NavLink to='/admin/documents' active={location.pathname.startsWith('/admin/documents')}>
              <DocumentText1 size='1.1rem' style={{ flexShrink: 0, marginRight: theme.spacing(SMALL_HORIZONTAL_SPACING) }} />
              Documents
            </NavLink>
            <NavLink to='/admin/matches' active={location.pathname.startsWith('/admin/matches')}>
              <BackwardItem size='1.1rem' style={{ flexShrink: 0, marginRight: theme.spacing(SMALL_HORIZONTAL_SPACING) }} />
              Transaction/Document Matches
            </NavLink>
            <NavLink to='/admin/assets' active={location.pathname.startsWith('/admin/assets')}>
              <Coin size='1.1rem' style={{ flexShrink: 0, marginRight: theme.spacing(SMALL_HORIZONTAL_SPACING) }} />
              Assets
            </NavLink>
            <NavLink to='/admin/statements' active={location.pathname.startsWith('/admin/statements')}>
              <TableDocument size='1.1rem' style={{ flexShrink: 0, marginRight: theme.spacing(SMALL_HORIZONTAL_SPACING) }} />
              Statements
            </NavLink>
            <NavLink to='/admin/payroll' active={location.pathname.startsWith('/admin/payroll')}>
              <MoneySend size='1.1rem' style={{ flexShrink: 0, marginRight: theme.spacing(SMALL_HORIZONTAL_SPACING) }} />
              Payroll
            </NavLink>
            <NavLink to='/admin/conversations' active={location.pathname.startsWith('/admin/conversations')}>
              <Message size='1.1rem' style={{ flexShrink: 0, marginRight: theme.spacing(SMALL_HORIZONTAL_SPACING) }} />
              Conversations
            </NavLink>
            <NavLink to='/admin/organizations' active={location.pathname.startsWith('/admin/organizations')}>
              <People size='1.1rem' style={{ flexShrink: 0, marginRight: theme.spacing(SMALL_HORIZONTAL_SPACING) }} />
              Organizations
            </NavLink>
            <NavLink to='/admin/calendar' active={location.pathname.startsWith('/admin/calendar')}>
              <Calendar size='1.1rem' style={{ flexShrink: 0, marginRight: theme.spacing(SMALL_HORIZONTAL_SPACING) }} />
              Calendar
            </NavLink>
            <NavLink to='/admin/jobs' active={location.pathname.startsWith('/admin/jobs')}>
              <Briefcase size='1.1rem' style={{ flexShrink: 0, marginRight: theme.spacing(SMALL_HORIZONTAL_SPACING) }} />
              Jobs
            </NavLink>
          </Stack>
        </Stack>
      }
      mainContent={children}
    />
  );
}
