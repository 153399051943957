import styled from 'styled-components';
import { SMALL_HORIZONTAL_SPACING, SMALL_VERTICAL_SPACING } from '../theme';

export const SimpleTable = styled.table`
  border-collapse: separate;
  border-spacing: 0px;

  > thead {
    > tr {
      > th {
        background: ${({ theme }) => theme.palette.background.default};
        padding-top: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
        padding-bottom: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
        padding-left: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
        padding-right: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};

        border-right: 1px solid ${({ theme }) => theme.palette.border.main};
        border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
      }

      > th:first-child,
      th:first-child {
        border-left: 1px solid ${({ theme }) => theme.palette.border.main};
      }
    }

    > tr:first-child {
      > th {
        border-top: 1px solid ${({ theme }) => theme.palette.border.main};
      }

      > :first-child {
        border-top-left-radius: ${({ theme }) => theme.roundedCorners(5)};
      }

      > :last-child {
        border-top: 1px solid ${({ theme }) => theme.palette.border.main};
        border-top-right-radius: ${({ theme }) => theme.roundedCorners(5)};
      }
    }

    th {
      font-weight: 500;
    }
  }

  > tbody {
    > tr {
      > td {
        background: ${({ theme }) => theme.palette.background.default};

        padding-top: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
        padding-bottom: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
        padding-left: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
        padding-right: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};

        border-right: 1px solid ${({ theme }) => theme.palette.border.main};
        border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
      }

      > td:first-child,
      th:first-child {
        border-left: 1px solid ${({ theme }) => theme.palette.border.main};
      }
    }

    > :last-child {
      > :first-child {
        border-left: 1px solid ${({ theme }) => theme.palette.border.main};
        border-bottom-left-radius: ${({ theme }) => theme.roundedCorners(5)};
      }

      > :last-child {
        border-bottom-right-radius: ${({ theme }) => theme.roundedCorners(5)};
      }
    }
  }
`;
